import $ from 'jquery';

$(function () {
	const select = $('.js-select1 select')

	if (select.length) {
		select.each(function () {
			toggleClassIsChose($(this))

			$(this).on('change', function () {
				toggleClassIsChose($(this))
			})
		})
	}

	// Check/uncheck all checkbox
	function handleCheckAllCheckbox(checkboxs, ischeck) {
		if (checkboxs.length) {
			checkboxs.each(function () {
				$(this).prop('checked', ischeck)
			})
		}
	}

	const jsAllCheck = $('.js-check-all')

	if (jsAllCheck.length) {
		jsAllCheck.each(function () {
			const _this = $(this)
			const btnCheckAll = _this.find('.js-btn-checkall')
			const btnUncheckAll = _this.find('.js-btn-uncheckall')
			const checkboxs = _this.find('.js-checkboxs input[type=checkbox]')
			const radios = _this.find('.js-radios input[type=radio]')

			btnCheckAll.length && btnCheckAll.on('click', () => handleCheckAllCheckbox(checkboxs, true))
			btnUncheckAll.length && btnUncheckAll.on('click', () => {
				handleCheckAllCheckbox(checkboxs, false)
				handleCheckAllCheckbox(radios, false)

				_this.find('.js-radios .nk23_c-listcheck__item:nth-child(1) input[type=radio]').prop('checked', true)
			})
		})
	}

	// Select change when other change
	const selectChange = $('.js-select-change')

	if (selectChange.length) {
		selectChange.each(function () {
			for (let i = 1; i <= 10; i++) {
				handleSelectFollow($(this), i)
			}
		})
	}

	function handleSelectFollow(ele, num) {
		const selectChoose = ele.find(`.js-select-choose${num}`)
		const selectFollow = ele.find(`.js-select-follow${num}`)

		selectChoose.on('change', () => {
			selectFollow.find('select').addClass('is-hide')

			switch (selectChoose.val()) {
				case '浦和':
					selectFollow.find('select.is-select1').removeClass('is-hide')
					break
				case '船橋':
					selectFollow.find('select.is-select2').removeClass('is-hide')
					break
				case '大井':
					selectFollow.find('select.is-select3').removeClass('is-hide')
					break
				case '川崎':
					selectFollow.find('select.is-select4').removeClass('is-hide')
					break
				default:
					new Error('Invalid value!')
			}
		})
	}

	//Clear checkbox
	const btnClear = $('.js-clear-form')

	if (btnClear.length) {
		btnClear.on('click', function () {
			$(this).parents('#contents').find('form input[type=checkbox], form input[type=radio]').prop('checked', false)
			$(this).parents('#contents').find('form input[type=text], form input[type=number], form input[type=tel], form input[type=email]').val('')
			$(this).parents('#contents').find('form select').prop("selected", false);
			$(this).parents('#contents').find('form select').val("0");
		})
	}

	//Active button when active radio buton
	const radioBtns = $('.js-radio-btns')

	if (radioBtns.length) {
		radioBtns.each(function () {
			const _this = $(this)
			const radios = $(this).find('input[type=radio]')

			radios.each(function () {
				$(this).on('change', function () {
					radios.attr('checked', false)
					_this.find('.js-radio-btn').removeClass('is-active')

					if ($(this).prop('checked')) {
						$(this).attr('checked', true)
						$(this).parent().addClass('is-active')
					}
				})
			})
		})
	}
})

function toggleClassIsChose(ele) {
	if (ele.val() !== '' && ele.val()) {
		ele.addClass('is-chose')
	} else {
		ele.removeClass('is-chose')
	}
}

// Change select of leading_kis page
$(function () {
	if ($("#kyori").length) {
		function zeroPadding(num) {
			return ('0000' + num).slice(-4);
		}

		const kyoList = {
			'00': [800, 900, 1000, 1200, 1300, 1400, 1500, 1600, 1650, 1700, 1800, 1900, 2000, 2100, 2200, 2400, 2600],
			'18': [800, 1300, 1400, 1500, 1600, 1900, 2000],
			'19': [1000, 1200, 1500, 1600, 1700, 1800, 2200, 2400],
			'20': [1000, 1200, 1400, 1500, 1600, 1650, 1700, 1800, 2000, 2400, 2600],
			'21': [900, 1400, 1500, 1600, 2000, 2100],
		};

		function setKyori(jo, kyori) {
			$("#kyori").empty();
			$("#kyori").append(
				"<option value=\"0000\" selected=\"selected\">全て</option>");
			for (let i = 0; i < kyoList[jo].length; i++) {
				const value = zeroPadding(kyoList[jo][i])

				$("#kyori").append(
					"<option value=\"" + value + "\">"
					+ kyoList[jo][i] + "</option>");

				if (kyoList[jo][i] == kyori) {
					document.getElementById('kyori').options[i + 1].selected = true;
				}
			}
		}

		$('#keibajo').on('change', function () {
			const jo = document.getElementById("keibajo").value
			const kyori = 0
			setKyori(jo, kyori)
		})

		setTimeout(function () {
			const jo = $('#keibajo').val();
			const kyori = $('#kyori').val();
			setKyori(jo, kyori);
		}, 200);
	}
})

//Custom selectbox
$(function () {
	if ($('.js-custom-select').length) {
		if ($(window).outerWidth() < 768) {
			let x, i, j, l, ll, selElmnt, a, b, c;
			x = document.getElementsByClassName("js-custom-select");
			l = x.length;
			for (i = 0; i < l; i++) {
				selElmnt = x[i].getElementsByTagName("select")[0];
				ll = selElmnt.length;
				/*for each element, create a new DIV that will act as the selected item:*/
				a = document.createElement("div");
				a.setAttribute("class", "select-selected");
				a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
				x[i].appendChild(a);
				/*for each element, create a new DIV that will contain the option list:*/
				b = document.createElement("div");
				b.setAttribute("class", "select-items select-hide");
				for (j = 1; j < ll; j++) {
					/*for each option in the original select element,
					create a new DIV that will act as an option item:*/
					c = document.createElement("div");
					c.innerHTML = selElmnt.options[j].innerHTML;
					c.addEventListener("click", function (e) {
						/*when an item is clicked, update the original select box,
						and the selected item:*/
						var y, i, k, s, h, sl, yl;
						s = this.parentNode.parentNode.getElementsByTagName("select")[0];
						sl = s.length;
						h = this.parentNode.previousSibling;
						for (i = 0; i < sl; i++) {
							if (s.options[i].innerHTML == this.innerHTML) {
								s.selectedIndex = i;
								h.innerHTML = this.innerHTML;
								y = this.parentNode.getElementsByClassName("same-as-selected");
								yl = y.length;
								for (k = 0; k < yl; k++) {
									y[k].removeAttribute("class");
								}
								this.setAttribute("class", "same-as-selected");
								break;
							}
						}
						h.click();
					});
					b.appendChild(c);
				}
				x[i].appendChild(b);
				a.addEventListener("click", function (e) {
					/*when the select box is clicked, close any other select boxes,
					and open/close the current select box:*/
					e.stopPropagation();
					closeAllSelect(this);
					this.nextSibling.classList.toggle("select-hide");
					this.classList.toggle("select-arrow-active");
				});
			}
			function closeAllSelect(elmnt) {
				/*a function that will close all select boxes in the document,
				except the current select box:*/
				let x, y, i, xl, yl, arrNo = [];
				x = document.getElementsByClassName("select-items");
				y = document.getElementsByClassName("select-selected");
				xl = x.length;
				yl = y.length;
				for (i = 0; i < yl; i++) {
					if (elmnt == y[i]) {
						arrNo.push(i)
					} else {
						y[i].classList.remove("select-arrow-active");
					}
				}
				for (i = 0; i < xl; i++) {
					if (arrNo.indexOf(i)) {
						x[i].classList.add("select-hide");
					}
				}
			}

			/*if the user clicks anywhere outside the select box,
			then close all select boxes:*/
			document.addEventListener("click", closeAllSelect);

			$(window).on('scroll', () => {
				closeAllSelect()
			})

			updateHeightOfSelect()

			function updateHeightOfSelect() {
				$('.js-custom-select').each(function () {
					let disSpace = $(window).innerHeight() + ($(window).scrollTop() - $(this).offset().top)

					if (disSpace > $(window).innerHeight()) {
						$(this).css('max-height', '50vh')

					} else {
						$(this).css('max-height', disSpace - 140)
					}
				})
			}
		}
	}
})
