import $ from 'jquery';
import { handleFixedScrolled } from './_Functions.js';

$(function () {
	const fixedEles = $('.js-sticky');
	const fixedElesLevel2 = $('.js-sticky-level2');

	handleFixedScrolled();

	function handleActiveSticky(_ele, heightEle) {
		if ($(window).outerWidth() < 768 && $('.smartphone').length) {
			_ele.addClass('is-sticky');
			_ele.parent().css('margin-bottom', heightEle);
		}
	}

	function handleInactiveSticky(_ele) {
		if ($(window).outerWidth() < 768 && $('.smartphone').length) {
			_ele.removeClass('is-sticky');
			_ele.parent().removeAttr('style');
		}
	}

	if (fixedEles.length) {
		fixedEles.each(function () {
			const _ele = $(this);
			const posEle = _ele.offset().top;
			const heightEle = _ele.innerHeight();

			$(window).on('scroll', function () {
				if ($(window).scrollTop() - posEle >= 0) {
					handleActiveSticky(_ele, heightEle);

					const disOffSticky = ($(window).scrollTop() - _ele.parents('body').find('.js-off-sticky').offset().top + 44)
					const isActiveSticky = disOffSticky >= 0;

					isActiveSticky ? handleInactiveSticky(_ele) : handleActiveSticky(_ele, heightEle);

				} else {
					handleInactiveSticky(_ele);
				}
			});
		});
	}

	if (fixedElesLevel2.length) {
		fixedElesLevel2.each(function () {
			const _ele = $(this);
			const par = $(this).parents('.js-sticky-wrap');
			const posEle = _ele.offset().top;

			$(window).on('scroll', function () {
				if ($(window).scrollTop() - posEle + 44 >= 0) {
					_ele.addClass('is-sticky');
					par.length && par.addClass('has-sticky');
				} else {
					_ele.removeClass('is-sticky');
					par.length && par.removeClass('has-sticky');
				}
			});
		});
	}

	// Sticky news sidebar
	const ele = $('.jsSideFixed');
	const hEle = $('.jsSideFixed').innerHeight();
	const footer = $('.js-footer');

	if (ele.length) {
		if ($(window).outerWidth() < 768) {
			ele.removeClass('is-sticky');
		}

		if ($(window).outerWidth() > 767) {
			$(window).on('scroll', () => {
				let hHeader = $('.js-header').innerHeight();
				let hSidebar = ele.outerHeight() + hHeader + 72;
				const offsetFooter = footer.offset().top - hEle - 56;
				const siblingEles = ele.siblings();

				siblingEles.each(function () {
					hSidebar += $(this).outerHeight();
				});

				const scrollTop = $(window).scrollTop();

				scrollTop >= (hSidebar + 16) ? ele.addClass('is-show') : ele.removeClass('is-show')

				scrollTop >= offsetFooter && ele.removeClass('is-show');

				scrollTop >= (hEle + ele.parent().offset().top + 100) ? ele.addClass('is-sticky') : ele.removeClass('is-sticky')

				scrollTop >= (hEle + ele.parent().offset().top + 150) ? ele.addClass('is-transition') : ele.removeClass('is-transition')
			});
		}
	}
})
