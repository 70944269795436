import $ from 'jquery';
import Chart from 'chart.js';

var ChartList = {};

const baseControllerBar = Chart.controllers.bar;
Chart.controllers.bar = Chart.controllers.bar.extend({
	draw: function () {
		baseControllerBar.prototype.draw.call(this, arguments);
		if (this._config.type === 'bar' && this._config.typeCustom === 'myBar') {
			let chart = this.chart;
			let width = chart.chartArea.right - chart.chartArea.left;
			let meta = this.getMeta();
			let view = meta.data[0]._view;
			let ctx = chart.ctx;

			ctx.save();
			ctx.fillStyle = 'rgba(0, 0, 0, 0.08)';
			ctx.fillRect(view.x, view.y, width, meta.data[0].height());
			ctx.restore();
		}
	}
});

window.createDateArr = function (dateObj, type) {
	let arr = [];

	if (dateObj instanceof Date) {
		arr.push(dateObj);
	}

	if (type === 'month') {
		for (let i = 1; i <= 12; i++) {
			let r = new Date(dateObj.getTime());
			r.setMonth(dateObj.getMonth() + i);
			arr.push(r);
		}
	}

	return arr;
}

window.chartLine1 = function (elem, dataJson) {
	let createChart = function (data) {
		let settings = {};

		if (typeof data.settings === 'object') {
			settings = data.settings;
			delete data.settings;
		}
		Chart.defaults.global.defaultFontColor = 'rgba(34, 31, 31, 1)';
		Chart.defaults.global.defaultFontFamily = "Noto Sans JP";
		Chart.defaults.global.defaultFontSize = 12;
		const chart = new Chart(elem, {
			type: 'line',
			data: data,
			options: {
				elements: {
					point: {
						radius: 7,
						hoverRadius: 7,
						pointStyle: 'circle',
						backgroundColor: 'rgba(255, 255, 255, 1)',
						borderWidth: 1,
						width: 14,
						height: 14
					},
					line: {
						tension: 0,
						borderWidth: 2,
						fill: false
					}
				},
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					xAxes: [{
						display: true,
						stacked: true,
						gridLines: {
							display: false
						},
						ticks: {
							autoSkip: false
						}
					}],
					yAxes: [{
						ticks: {
							min: getChartSetting(settings.min, 1),
							max: getChartSetting(settings.max, 12),
							stepSize: getChartSetting(settings.step, 1),
							reverse: true,
							display: false
						},
						gridLines: {
							drawBorder: false
						}
					}]
				},
				layout: {
					padding: {top: 8, left: 16, bottom: 8, right: 16}
				},
				legend: {
					display: false
				},
				tooltips: {
					enabled: false
				}
			},
			plugins: [{
				afterDatasetsDraw: function (chart) {
					let ctx = chart.ctx;
					chart.data.datasets.forEach(function (dataset, i) {
						let meta = chart.getDatasetMeta(i);
						if (!meta.hidden) {
							meta.data.forEach(function (element, index) {
								ctx.fillStyle = 'rgba(255, 255, 255, 1)';

								let fontSize = 10;
								let fontStyle = 'normal';
								let fontFamily = 'Noto Sans JP';
								ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);

								let dataString = dataset.data[index].toString();
								ctx.textAlign = 'center';
								ctx.textBaseline = 'middle';

								let padding = -2; // グラフの点の数値の余白
								let position = element.tooltipPosition();
								ctx.fillText(dataString, position.x, position.y - (fontSize / 6) - padding);
							});
						}
					});
				}
			}]
		});
		return chart;
	}

	if (typeof dataJson === 'string') {
		fetch(dataJson).then(function (response) {
			return response.json();
		}).then(function (json) {
			createChart(json);
		}).catch(function (error) {
			console.log(error);
		});
	} else if (typeof dataJson === 'object') {
		return createChart(dataJson);
	}
}

window.chartLine2 = function (elem, dataJson) {
	let createChart = function (data) {
		let settings = {};
		if (typeof data.settings === 'object') {
			settings = data.settings;
			delete data.settings;
		}
		Chart.defaults.global.defaultFontColor = 'rgba(34, 31, 31, 1)';
		Chart.defaults.global.defaultFontFamily = "Noto Sans JP";
		Chart.defaults.global.defaultFontSize = 12;
		Chart.defaults.global.backgroundColor = 'rgba(255, 255, 255, 1)';
		Chart.defaults.global.hoverBackgroundColor = 'rgba(255, 255, 255, 1)';
		Chart.defaults.global.borderColor = 'rgba(34, 31, 31, 1)';
		Chart.defaults.global.hoverBorderColor = 'rgba(34, 31, 31, 1)';
		ChartList[elem.canvas.id] = new Chart(elem, {
			type: 'bar',
			data: data,
			options: {
				elements: {
					line: {
						tension: 0,
						borderWidth: 2,
						borderColor: 'rgba(34, 31, 31, 1)',
						hoverBorderColor: 'rgba(34, 31, 31, 1)',
						backgroundColor: 'rgba(34, 31, 31, 1)',
						hoverBackgroundColor: 'rgba(34, 31, 31, 1)',
						fill: false
					},
					point: {
						radius: 12,
						hoverRadius: 12,
						pointStyle: 'circle',
						borderWidth: 1,
						borderColor: 'rgba(34, 31, 31, 1)',
						hoverBorderColor: 'rgba(34, 31, 31, 1)',
						backgroundColor: 'rgba(255, 255, 255, 1)',
						hoverBackgroundColor: 'rgba(255, 255, 255, 1)',
						width: 24,
						height: 24
					}
				},
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					xAxes: [{
						type: 'time',
						ticks: {
							source: 'labels',
							callback: function (value, index, values) {
								if (index === (values.length - 1)) {
									return '(年/月)';
								} else {
									return value;
								}
							}
						},
						time: {
							unit: 'month',
							displayFormats: {
								month: 'YY/MM'
							},
							tooltipFormat: 'YYYY/MM/DD'
						},
						gridLines: {
							display: false
						}
					}],
					yAxes: [{
						display: true,
						position: 'left',
						ticks: {
							stepSize: getChartSetting(settings.step, 10),
							min: getChartSetting(settings.min, 0),
							max: getChartSetting(settings.max, 0)
						},
						gridLines: {
							color: 'rgba(201, 201, 201, 1)',
						}
					}, {
						display: window.innerWidth <= 767,
						position: 'right',
						ticks: {
							stepSize: getChartSetting(settings.step, 10),
							min: getChartSetting(settings.min, 0),
							max: getChartSetting(settings.max, 0)
						},
						gridLines: {
							color: 'rgba(201, 201, 201, 1)',
						}
					}]
				},
				layout: {
					padding: {top: 10, left: 10, bottom: 8, right: 8}
				},
				legend: {
					display: false
				},
				tooltips: {
					enabled: false
				}
			},
			plugins: [{
				resize: function (chart) {
					chart.options.scales.yAxes[1].display = window.innerWidth <= 767;
					chart.update();
				},
				afterInit: function (chart) {
					let myBarIndex = false;
					chart.data.datasets.forEach(function (dataset, i) {
						if (dataset.type === 'bar' && dataset.typeCustom === 'myBar') {
							myBarIndex = i;
							chart.data.datasets[i].backgroundColor = 'rgba(0,0,0,0)';
						}
						if (dataset.type === 'line' && dataset.typeCustom === 'myLine') {
							let colors = [];
							dataset.data.forEach(function (data) {
								colors.push(data.color);
							});
							if (colors.length) {
								chart.data.datasets[i].backgroundColor = colors;
								chart.data.datasets[i].hoverBackgroundColor = colors;
							}
						}
					});
					if (myBarIndex !== false) {
						chart.options.tooltips.filter = function (item) {
							return !(item.datasetIndex === myBarIndex);
						};
					}
					chart.update();
				},
				afterDatasetsDraw: function (chart) {
					const ctx = chart.ctx;
					chart.data.datasets.forEach(function (dataset, i) {
						let meta = chart.getDatasetMeta(i);
						if (!meta.hidden && dataset.type === 'line') {
							meta.data.forEach(function (element, index) {
								ctx.fillStyle = 'rgba(34, 31, 31, 1)';

								let fontSize = 14;
								let fontStyle = 'normal';
								let fontFamily = 'Noto Sans JP';
								ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);

								let dataString = dataset.data[index]['label'].toString();
								ctx.textAlign = 'center';
								ctx.textBaseline = 'middle';

								let padding = -2; // グラフの点の数値の余白
								let position = element.tooltipPosition();
								ctx.fillText(dataString, position.x, position.y - (fontSize / 6) - padding);
							});
						}
					});
				}
			}]
		});
	}

	if (typeof dataJson === 'string') {
		fetch(dataJson).then(function (response) {
			return response.json();
		}).then(function (json) {
			createChart(json);
		}).catch(function (error) {
			console.log(error);
		});
	} else if (typeof dataJson === 'object') {
		createChart(dataJson);
	}
}

window.chartList1 = function (elem, data) {
	if (Array.isArray(data)) {
		const list = $('<ul class="nk23_c-chart1__list"></ul>');
		data.forEach(function (val) {
			const item = $('<li class="nk23_c-chart1__item">' + val.label
				+ '<span class="is-bar"><span class="is-percent is-init" style="width: '
				+ parseFloat(val.data) + '%; background-color: ' + val.color + ';"></span></span></li>');
			list.append(item);
		});
		list.appendTo(elem);
	}
}

window.chartList2 = function (elem, dataJson) {
	let getChart = function (dataJson) {
		let listData = [];
		let sumData = [];

		let type = dataJson.type;

		dataJson.datasets.forEach(function (value, index) {
			let item = $('<div class="nk23_c-chart4__item1"></div>');
			let num = $('<span class="nk23_c-chart4__num1"></span>');
			let wrap = $('<div class="nk23_c-chart4__wrap1"></div>');
			let info1 = $('<div class="nk23_c-chart4__info1"></div>');
			let info2 = $('<ul class="nk23_c-chart4__info2"></ul>');
			let link = $('<a class="nk23_c-chart4__link1" href="#"></a>');
			let title = $('<p class="nk23_c-chart4__title1"></p>');
			let rate = $('<ul class="nk23_c-chart4__rate"></ul>');

			num.text(index + 1).css({
				'background-color': value.backgroundColor,
				'border-color': value.boderColor,
				'color': value.color,
			}).appendTo(item);

			link.text(value.label1).attr('href', value.url).appendTo(info1);
			title.text(value.label2).appendTo(info1);

			let sum = 0;
			value.data.slice(0, 4).forEach(function (val, i) {
				sum += val;
				let iText = (i === 3) ? '着以下' : '着';
				$('<li>' + (i + 1) + iText + val + '回</li>').appendTo(info2);
			});

			value.rate.forEach(function (val) {
				$('<li class="is-rate">' + val + '</li>').appendTo(info2);
				$('<li class="is-rate">' + val + '</li>').appendTo(rate);
			});

			sumData.push(sum);
			listData.push({item, wrap, info1, info2, value, rate});
		});

		let max = Math.max(...sumData);

		// Custom max value
		if (typeof dataJson.max === 'number') {
			// base2（枠番馬番グラフ）の場合maxはjsonから取得、その他の場合は算出したmaxより大きい場合jsonから取得
			if (type === 'base2' || (type !== 'base2' && dataJson.max > max)) {
				max = Math.ceil(dataJson.max);
			}
		}

		let item, step, maxnum;

		if (type === 'base40') {
			// base40（種牡馬分析グラフ）
			item = 4;
			step = 5;
			let maxBase10 = Math.ceil(Math.ceil(max) / 10);
			if (maxBase10 <= 4) {
				if ((maxBase10 % 2) === 1) {
					maxBase10 += 1;
				}
			} else {
				maxnum = maxBase10 % 4;
				if ((maxnum) >= 1 && (maxnum) <= 3) {
					maxBase10 += (4 - maxnum);
				}
			}
			max = maxBase10 * 10;
			step = Math.ceil(max / item);
		} else {
			//base2 default
			if ((max % 2) === 1) {
				max += 1;
			}

			step = 2;
			// Custom step value
			if (typeof dataJson.step === 'number') {
				step = Math.ceil(dataJson.step);
			}

			item = max / step;
			while (!Number.isInteger(item)) {
				max += 1;
				item = max / step;
			}
		}

		let list = $('<div class="nk23_c-chart4__list2"></div>');
		let grid = $('<div class="nk23_c-chart4__grid"></div>');
		let gridInner = $('<ul class="nk23_c-chart4__gridInner"></ul>');
		for (let g = 0; g <= item; g++) {
			let stepVal = (g === 0) ? 0 : (g * step);
			$('<li><span class="nk23_c-chart4__gridNum">' + stepVal + '</span></li>').appendTo(gridInner);
		}
		gridInner.appendTo(grid);
		grid.appendTo(list);

		listData.forEach(function (data) {
			let bar = $('<div class="nk23_c-chart4__bar1"></div>');
			let percent = $('<ul class="nk23_c-chart4__percent"></ul>');

			data.value.data.slice(0, 4).forEach(function (val, i) {
				let iTitle = (i === 3) ? '着以下' : '着';
				let percentLi = $('<li class="is-percent-' + i + '" title="' + (i + 1) + iTitle + val + '回"></li>');
				percentLi.css('width', ((val * 100) / max) + '%');
				percentLi.appendTo(percent);
			});

			let rateLi = $('<li class="is-percent-rate">');
			rateLi.append(data.rate);
			rateLi.appendTo(percent);

			percent.appendTo(bar);
			data.info1.appendTo(data.wrap);
			bar.appendTo(data.wrap);
			data.wrap.appendTo(data.item);
			data.info2.appendTo(data.item);
			data.item.appendTo(list);

			const getPercentRatePos = function (ev, thisElem, rate) {
				let rateWidth = rate.outerWidth();
				let thisWidth = thisElem.width();
				let posLeft = ev.pageX - thisElem.offset().left;

				if (posLeft + rateWidth > thisWidth) {
					posLeft = thisWidth - rateWidth;
				}

				rate.css('left', posLeft + 'px');
			}

			percent.on('click', function (ev) {
				if ($(this).hasClass('show-rate')) {
					$('.nk23_c-chart4__percent').removeClass('show-rate');
					$('.nk23_c-chart4__rate').removeClass('show');
					setTimeout(function () {
						$('.is-percent-rate').removeAttr('style');
					}, 300);
				} else {
					$('.nk23_c-chart4__percent').removeClass('show-rate');
					$('.nk23_c-chart4__rate').removeClass('show').removeAttr('style');
					$(this).addClass('show-rate');

					getPercentRatePos(ev, $(this), $('.nk23_c-chart4__rate', this).parent());
					$('.nk23_c-chart4__rate', this).addClass('show');
				}
			});
		});

		list.appendTo($(elem));
	}

	if (typeof dataJson === 'string') {
		fetch(dataJson).then(function (response) {
			return response.json();
		}).then(function (json) {
			getChart(json);
		}).catch(function (error) {
			console.log(error);
		});
	} else if (typeof dataJson.type !== 'undefined') {
		getChart(dataJson);
	}

	$('body').on('click', function (ev) {
		let showRate = $('.nk23_c-chart4__percent.show-rate');
		if (showRate.length) {
			if (!showRate.get(0).contains(ev.target)) {
				showRate.removeClass('show-rate');
				$('.nk23_c-chart4__rate', showRate).removeClass('show');
			}
		}
	});
}

function getChartSetting(value, defaultValue) {
	if (typeof value === 'undefined' || value === null || value === '') {
		return defaultValue;
	}

	return value;
}

$(function () {
	$('.js-scroll-chart').each(function () {
		this.scrollLeft = this.scrollWidth - this.clientWidth;
	});

	$('[data-select-chart]').on('change', function () {
		const chartId = $(this).data('select-chart');
		const chartElem = $('#' + chartId);
		const chart = ChartList[chartId];
		// 馬体重分析画面の年選択プルダウン
		let select = document.querySelector('select#bataijyuSelectbox.nk23_c-selectbox__select');
		let index = null;
		// プルダウン内で選択された項目が先頭から何番目か（馬体重分析画面でのみ取得）
		if (select) {
			index = select.selectedIndex;
		}
		const data = $(this).val();
		// プルダウン選択によりグラフ再表示が必要となる画面（馬体重分析）への対処
		if (chartElem.length && chart && data) {
			chart.destroy();
			// dataList[index]：umaWeightBunseki.vm内のjavascriptで宣言されたグラフ用データ
			// data：モック動作用（プルダウン項目の各valueの値にjsonデータのファイルパスを指定）
			window.chartLine2(chartElem.get(0).getContext('2d'), index ? dataList[index] : data);
		}
	});
});

$(window).on('load', function () {
	$('.chart-js1 .is-percent.is-init').removeClass('is-init');
});
