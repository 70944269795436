import $ from 'jquery';
import Scrollbar from 'smooth-scrollbar';

$(function () {
	const scrollbar = Scrollbar.initAll();

	const iconScroll = $('.js-table-icon')
	let timeouts = []

	const getoOfsetTop = function (elem) {
		let offsetTop = elem.offsetTop;
		if (elem.offsetParent) {
			offsetTop += getoOfsetTop(elem.offsetParent);
		}
		return offsetTop;
	}

	const checkScrollTo = function (icon) {
		if (icon.hasClass('is-show')) {
			let offsetTop = getoOfsetTop(icon.get(0)) + icon.outerHeight();
			let scrollTop = $(window).scrollTop() + $(window).innerHeight();
			if (scrollTop >= offsetTop) {
				timeouts.push(
					setTimeout(function () {
						//icon.removeClass('is-show');
						icon.addClass('is-hide');
					}, 5000)
				)
			}
		}
	}

	function activeHandleIconTable() {
		if (timeouts.length) {
			for (let i = 0; i < timeouts.length; i++) {
				clearTimeout(timeouts[i]);
			}
			timeouts = []
		}

		scrollbar.map((item) => {
			const icon = $('.js-table-icon', $(item.containerEl).parent());

			if (icon.length) {
				//$(item.containerEl).parent().get(0).ontouchstart = () => icon.removeClass('is-show');
				$(item.containerEl).parent().get(0).ontouchstart = () => icon.addClass('is-hide');

				if (!$(this).hasClass('is-active')) {
					iconScroll.addClass('is-show')
				}
			}
		});

		$('.js-icontable-custom').length && $('.js-icontable-custom').addClass('is-show')
	}

	for (let i = 1; i <= 10; i++) {
		const btnTabs = $(`.js-tab${i}-btn`)

		btnTabs.each(function () {
			$(this).on('click', function () {
				activeHandleIconTable()
				hideIconTableCustom('.js-icontable-custom')

				scrollbar.map((item) => {
					const icon = $('.js-table-icon', $(item.containerEl).parent());

					if (icon.length) {
						checkScrollTo(icon);

						//$(item.containerEl).parent().get(0).ontouchstart = () => icon.removeClass('is-show');
						$(item.containerEl).parent().get(0).ontouchstart = () => icon.addClass('is-hide');
					}
				});
			})
		})
	}

	let scrollTable = false
	let scrollValue = 0
	let showTitleTopX = false
	let showTitleTopY = false

	scrollbar.map((item) => {
		const icon = $('.js-table-icon', $(item.containerEl).parent());
		const scrollfixedWrap = $(item.containerEl).parents('.js-scrollfixed-wrap')
		const scrollX = $(item.containerEl).find('.scrollbar-track-x')
		// Add strix St
		const scrollfixedTop = scrollfixedWrap.find('.js-scroll-fixed-top')
		// Add strix End
		const scrollfixedCol = scrollfixedWrap.find('.js-scroll-fixed-col')
		const scrollfixedColActive = scrollfixedWrap.find('.js-scroll-fixed-col.is-active')

		setTimeout(() => {
			if (window.getComputedStyle(scrollX[0], null).display === 'none') {
				$(item.containerEl).removeClass('margin-bottom')
			} else {
				$(item.containerEl).addClass('margin-bottom')
			}
		}, 0);

		if (icon.length) {
			checkScrollTo(icon);

			$(window).on('scroll', function () {
				checkScrollTo(icon);
			});

			const jsScrollbarScroll = $('.js-scrollbar-scroll')

			if (jsScrollbarScroll.length) {
				jsScrollbarScroll.each(function () {
					$(this).on('scroll', function () {
						hideIconTableCustomScroll(icon)
					})
				})
			}

			$(item.containerEl).parent().on('click', function () {
				//icon.removeClass('is-show');
				icon.addClass('is-hide');
			})

			//$(item.containerEl).parent().get(0).ontouchstart = () => icon.removeClass('is-show');
			$(item.containerEl).parent().get(0).ontouchstart = () => icon.addClass('is-hide');

			item.addListener(function (status) {
				$(item.containerEl).trigger('scrollbar-event', [status]);

				$(item.containerEl).find('.dataTables_wrapper').css({
					right: -status.offset.x,
				})

				scrollfixedWrap.find('.js-scroll-fixed-top').css({
					left: -status.offset.x,
				})

				// Add strix St
				scrollfixedWrap.find('.js-scroll-fixed-col').css({
					top: -status.offset.y,
				})
				// Add strix End

				if ($('.js-fixed-ele').length) {
					$(item.containerEl).parents('.js-fixed').find('.js-fixed-ele').scrollLeft(status.offset.x)
				}

				if ($('.js-fixed1-ele').length) {
					$(item.containerEl).parents('.js-fixed1').find('.js-fixed1-ele').scrollLeft(status.offset.x)
				}

				// Add strix St
				// 表ヘッダーを上部固定
				let thisScrollFixedTopHeight = 0;
				let thisTab = scrollfixedWrap.parent().data('tab2-content');

				// Add strix St 23.12.13
				if (thisTab == 'tab1') {
					thisScrollFixedTopHeight = 53;
				} else if (thisTab == 'tab2') {
					thisScrollFixedTopHeight = 170;
				}
				// End strix St 23.12.13

				if (status.offset.y > thisScrollFixedTopHeight) {
					scrollfixedTop.addClass('is-active')
					$('.js-scroll-fixed-titletop .nk23_c-table22__titlecontent').addClass('is-active-y')
					showTitleTopX = true

				} else {
					showTitleTopX = false
					scrollfixedTop.removeClass('is-active')
					$('.js-scroll-fixed-titletop .nk23_c-table22__titlecontent').removeClass('is-active-y')
				}
				// Add strix End

				if (status.offset.x > 145) {
					scrollfixedCol.addClass('is-active')
					showTitleTopY = true
					// showTitleTopX && $('.js-scroll-fixed-titletop .nk23_c-table22__titlecontent').addClass('is-active-x')
					$('.js-scroll-fixed-titletop .nk23_c-table22__titlecontent').addClass('is-active-x')

				} else {
					showTitleTopY = false
					scrollfixedCol.removeClass('is-active')
					$('.js-scroll-fixed-titletop .nk23_c-table22__titlecontent').removeClass('is-active-x')
				}

				if (scrollfixedColActive.length) {
					scrollfixedColActive.css('top', scrollfixedWrap.offset().top - $(window).scrollTop())
				}

				if (status.offset.x > 0) {
					scrollValue = status.offset.x
					scrollTable = true
				}
			});

			// ドラッグ操作
			let is_drag = false;
			let dragX = 0;
			let moveX = 0;
			let numX = 0; // 移動量

			// マウスクリック
			$(item.containerEl).off('mousedown');
			$(item.containerEl).on('mousedown', function (e) {
				is_drag = true;
				dragX = e.clientX;

				// マウスドラッグ
				$(item.containerEl).off('mousemove');
				$(item.containerEl).on('mousemove', function (e) {
					if (is_drag === true) {
						e.preventDefault();

						$(this).addClass('is-drug is-circle-disable');
						moveX = e.clientX;
						numX = dragX - moveX;

						if (dragX < moveX) {
							// 左に移動
							item.scrollLeft += numX;
							dragX = moveX;
						} else if (dragX > moveX) {
							// 右に移動
							item.scrollLeft += numX;
							dragX = moveX;
						}
					}
				});

			});


			// マウスドラッグ終了
			$(item.containerEl).off('mouseup mouseleave');
			$(item.containerEl).on('mouseup mouseleave', function (e) {
				is_drag = false;
				dragX = 0;
				moveX = 0;
				$(item.containerEl).off('mousemove');
				$(this).removeClass('is-drug');
				setTimeout(() => {
					$(this).removeClass('is-circle-disable');
				}, 100)
			});
		}

		if ($(item.containerEl).hasClass('js-scrollbar-right')) {
			function isFontLoaded(font, ele) {
				const loadedFontFamily = `${font}`;
				const testElement = document.querySelector(ele);
				const computedFontFamily = window.getComputedStyle(testElement).getPropertyValue('font-family');

				return computedFontFamily === loadedFontFamily;
			}

			if ($(window).outerWidth() > 767) {
				let timter = setInterval(() => {
					const transfromX = item.getSize().content.width - $(window).outerWidth()

					item.setPosition(transfromX, 0)
				}, 100);

				$('.js-scrollbar-right table').get(0).ontouchstart = () => {
					if (timter) {
						clearInterval(timter);
						timter = null;
					}
				}
				// PCブラウザの場合のタイマークリア処理
				item.addListener(function() {
					// Smooth Scrollbarの位置が変化した
					if (timter) {
						clearInterval(timter);
						timter = null;
					}
				});
				var eventName = ('onwheel' in window || document.implementation.hasFeature('Events.wheel', '3.0')) ? 'wheel' : 'mousewheel';
				$('.js-scrollbar-right table').on(eventName, function () {
					if (timter) {
						clearInterval(timter);
						timter = null;
					}
				});

				setTimeout(() => {
					if (timter) {
						clearInterval(timter);
						timter = null;
					}
				}, 10000);

			} else {
				const transfromX = item.getSize().content.width - $(window).outerWidth()

				item.setPosition(transfromX, 0)
			}
		}
	});

	// Fixed top row of table22
	const jsFixedTop = $('.js-scrollfixed-wrap')
	// Add strix St
	if (jsFixedTop.length) {
		jsFixedTop.each(function () {
			const _eleS = $(this);
			let posEleS = _eleS.offset().top - 44;
			let posEleS2 = _eleS.offset().top + 170 - 44;
			let heightEleS = _eleS.innerHeight() + 60;

			$(window).on('scroll', function () {

				if (_eleS.offset().top == 0 && _eleS.innerHeight() == 0) {
					return false;
				} else {

					if (posEleS <= 0) {
						posEleS = _eleS.offset().top - 44;
						heightEleS = _eleS.innerHeight() + 60;
					}
					if (posEleS2 <= 126) {
						posEleS2 = _eleS.offset().top + 170 - 44;
					}

					if ($(window).scrollTop() - posEleS >= 0) {

						handleFixedTop(_eleS, heightEleS);

						const isAcSticky =
							$(window).scrollTop() -
							_eleS.parents('body').find('.js-off-sticky').offset().top +
							44 >=
							0;

						isAcSticky
							? handleFixedTopSticky(_eleS)
							: handleFixedTop(_eleS, heightEleS);

					} else {
						handleFixedTopSticky(_eleS);
					}

					// ページスクロールに合わせてヘッダー固定
					if ($(window).scrollTop() - posEleS2 >= 0) {
						handleFixedTop2(_eleS, heightEleS);

						const isAcSticky =
							$(window).scrollTop() -
							_eleS.parents('body').find('.js-off-sticky').offset().top +
							44 >=
							0;

						isAcSticky
							? handleFixedTop2Sticky(_eleS)
							: handleFixedTop2(_eleS, heightEleS);

					} else {
						handleFixedTop2Sticky(_eleS);
					}
				}

			});
		});
	}
	function handleFixedTop(_eleS, heightEleS) {
		// _eleS.addClass('is-sticky');
		$('#wrapper').addClass('is-sticky');

		// $(window).outerWidth() < 768 && _eleS.parent().css('margin-bottom', heightEleS);

	}
	function handleFixedTopSticky(_eleS) {
		// _eleS.removeClass('is-sticky');
		$('#wrapper').removeClass('is-sticky');
		_eleS.parent().removeAttr('style');
	}

	function handleFixedTop2(_eleS, heightEleS) {
		$('#wrapper').addClass('is-sticky2');
	}
	function handleFixedTop2Sticky(_eleS) {
		$('#wrapper').removeClass('is-sticky2');
		_eleS.parent().removeAttr('style');
	}
	// Add strix End

	// if (jsFixedTop.length) {
	//   $(window).on('scroll', function () {
	//     jsFixedTop.each(function () {
	//       const scrollfixedWrap = $(this).parents('.js-scrollfixed-wrap')
	//       const zoomCotent = scrollfixedWrap.find('.js-zoom-content')
	//       const jsSticky = $(this).parents('main').find('.js-sticky')

	//       if ($(this).parent().find('.js-scroll-fixed-col').length) {
	//         if (scrollfixedWrap.offset().top > 0) {
	//           const isHideSticky = ($(window).scrollTop() - zoomCotent.offset().top - zoomCotent[0].getBoundingClientRect().height) >= 0
	//           const isHideStickyCustom = ($(window).scrollTop() - zoomCotent.offset().top - zoomCotent[0].getBoundingClientRect().height + 160) >= 0
	//           const dis = $(window).scrollTop() - scrollfixedWrap.offset().top - scrollfixedWrap.find('.js-scroll-fixed-title')[0].getBoundingClientRect().height + 44 >= 0

	//           $(this).css(scrollTable ? { left: -scrollValue } : { left: 0 })

	//           // dis ? $(this).addClass('is-active') : $(this).removeClass('is-active')
	//           if (dis) {
	//             $(this).addClass('is-active')
	//             $('.js-scroll-fixed-titletop .nk23_c-table22__titlecontent').addClass('is-active-y')
	//             showTitleTopY && $('.js-scroll-fixed-titletop .nk23_c-table22__titlecontent').addClass('is-active-x')
	//             showTitleTopX = true

	//           } else {
	//             $('.js-scroll-fixed-titletop .nk23_c-table22__titlecontent').removeClass('is-active-y')
	//             $(this).removeClass('is-active')
	//             showTitleTopX = false
	//           }

	//           if (isHideStickyCustom) {
	//             $(this).removeClass('is-active')
	//             $('.js-scroll-fixed-titletop .nk23_c-table22__titlecontent').removeClass('is-active-y')
	//           }

	//           isHideSticky ? jsSticky.removeClass('is-sticky') : jsSticky.addClass('is-sticky')
	//         }
	//       }
	//     })

	//   })
	// }

	// Custom scrollbar for table has fixed column
	function customScrollBar() {
		const customScrollbar = $('.js-custom-scrollbar')

		if (customScrollbar.length) {
			customScrollbar.each(function () {

				const inner = $(this).find('.custom-scrollbar-inner')
				const table = $(this).find('table')
				const scrollbar = $(this).find('.custom-scrollbar')
				const scrollbarX = $(this).find('.custom-scrollbar-x')
				const icon = $(this).find('.custom-scrollbar-icon')

				if (inner.innerWidth() < table.innerWidth()) {
					scrollbar.removeClass('is-hidden')
					icon.addClass('is-show')

					const per = inner.innerWidth() / table.innerWidth() * 100

					scrollbarX.css('width', per + '%')

					$(this).get(0).ontouchstart = () => icon.removeClass('is-show')

				} else {
					icon.removeClass('is-show')
					scrollbar.addClass('is-hidden')
				}

				inner.on('scroll', function () {
					const transx = $(this).scrollLeft() / table.innerWidth() * 100

					scrollbarX.css({
						left: transx + '%'
					})
				})
			})
		}
	}

	customScrollBar()

	$(window).on('resize', function () {
		setTimeout(() => {
			customScrollBar()
		}, 1000);
	})

	function hideIconTableCustom(iconsCustom) {

		if ($(iconsCustom).length) {

			$(iconsCustom).each(function () {

				const offsetTopCustom = $(this).offset().top + $(this).outerHeight();
				const scrollTopCustom = $(window).scrollTop() + $(window).innerHeight();

				if (scrollTopCustom >= offsetTopCustom) {
					if ($(this).hasClass('is-show')) {
						const _ele = $(this)

						timeouts.push(
							setTimeout(function () {
								_ele.removeClass('is-show');
							}, 5000)
						)
					}
				}
			})
		}
	}

	function hideIconTableCustomScroll(iconsCustom) {
		const icons = $('.js-scrollbar-scroll').find(iconsCustom)

		if ($(icons).length) {
			$(icons).each(function () {
				if ($('.js-scrollbar-scroll').length) {
					const offsetTopCustom = $(this).offset().top + $(this).outerHeight();
					const scrollTopCustom = $('.js-scrollbar-scroll').scrollTop() + $('.js-scrollbar-scroll').innerHeight();

					if (scrollTopCustom >= offsetTopCustom) {
						if ($(this).hasClass('is-show')) {
							const _ele = $(this)

							timeouts.push(
								setTimeout(function () {
									_ele.removeClass('is-show');
								}, 5000)
							)
						}
					}
				}

			})
		}
	}

	hideIconTableCustom('.js-icontable-custom')

	$(window).on('scroll', () => hideIconTableCustom('.js-icontable-custom'))

	const jsScrollbarScroll = $('.js-scrollbar-scroll')

	if (jsScrollbarScroll.length) {
		jsScrollbarScroll.each(function () {
			$(this).on('scroll', function () {
				hideIconTableCustomScroll('.js-icontable-custom')
			})
		})
	}

	const jsCloseModal = $('.js-close-modal1')

	if (jsCloseModal.length) {
		jsCloseModal.on('click', () => {
			if (timeouts.length) {
				for (let i = 0; i < timeouts.length; i++) {
					clearTimeout(timeouts[i]);
				}
				timeouts = []
			}
		})
	}

	const btnModals = $('.js-modal1-btn')

	if (btnModals.length) {
		btnModals.each(function () {
			$(this).on('click', function () {
				const srollbarModal = $('.js-scrollbar-scroll')

				if (timeouts.length) {
					for (let i = 0; i < timeouts.length; i++) {
						clearTimeout(timeouts[i]);
					}
					timeouts = []
				}

				if (srollbarModal.length) {
					srollbarModal.each(function () {
						const iconTable = $(this).find('.js-table-icon')

						if (iconTable.length) {
							iconTable.each(function () {
								$(this).addClass('is-show')
							})
						}
					})
				}

			})
		})
	}
});
