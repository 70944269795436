import $ from 'jquery';
import DataTable from 'datatables.net-dt';

import { detectIos } from './_Functions.js';

$(function () {
	$('[data-onjun-click]').on('click', function () {
		let onjunNo = $(this).data('onjun-click');
		let parent = $(this).parents('[data-js="onjunSwitch"]');
		if (parent.length) {
			let onjunswitch = parent.data('onjun-switch');
			let tableElems = $(onjunswitch);
			if (tableElems.length) {
				let table = tableElems.get(0);
				if (onjunNo === 'top') {
					$('.syubobaList2_tr', table).show(0);
				} else {
					$('.syubobaList2_tr', table).hide(0);
					$('.syubobaList2_' + onjunNo, table).show(0);
				}
				$('[data-onjun-switch="' + onjunswitch + '"] [data-onjun-click]').removeClass('is-active');
				$('[data-onjun-switch="' + onjunswitch + '"] [data-onjun-click="' + onjunNo + '"]').not(this).addClass('is-active');
				$(this).addClass('is-active');
			}
		}
	});
});

$(function () {
	new DataTable('.js-datatable-select', {
		dom: '<"nk23_c-selectbox js-select1 nk23_c-selectbox--style1"l>',
		ordering: false,
		searching: false,
		autoWidth: false,
		info: false,
		lengthChange: true,
		lengthMenu: [1, 2, 3, 4, 5],
		pageLength: 5,
		language: {
			lengthMenu: '表示件数 _MENU_',
		},
	});

	$('.js-datatable-select01').DataTable({
		columnDefs: [
			{
				targets: [0, 1],
				colspan: 2,
			},
			{
				targets: [2, 3],
				colspan: 2,
			},
			{
				targets: [4, 5],
				colspan: 2,
			},
			{
				targets: [6, 7],
				colspan: 2,
			},
			{
				targets: [8, 9],
				colspan: 2,
			},
			{
				targets: [10, 11],
				colspan: 2,
			},
			{
				targets: [12, 13],
				colspan: 2,
			},
			{
				targets: [14, 15],
				colspan: 2,
			},
		],
		dom: '<"nk23_c-selectbox js-select1 nk23_c-selectbox--style1"l>',
		ordering: false,
		searching: false,
		autoWidth: false,
		info: false,
		lengthChange: true,
		lengthMenu: [
			[5, 10, 15, 30, 50, -1],
			[5, 10, 15, 30, 50, 'All'],
		],
		pageLength: 5,
		language: {
			lengthMenu: '<a href="#">この馬を印刷</a> _MENU_',
		},
	});
});

$(function () {
	initializeDataTables('.js-tableSort');

	function initializeDataTables(tableSelector) {
		$(tableSelector).each(function () {
			var table = $(this).DataTable({
				searching: false,
				info: false,
				autoWidth: false,
				lengthChange: false,
				paging: false,
				order: [],
				columnDefs: [
					{
						targets: 'no-sort',
						orderable: false,
					},
				],
			});

			var columnClicked = -1;
			var clickCount = 0;

			$('thead th', this).on('click', function () {
				var columnIndex = table.column(this).index();

				if (columnIndex === columnClicked) {
					clickCount++;
				} else {
					columnClicked = columnIndex;
					clickCount = 1;
				}

				if (clickCount === 1) {
					table.order([columnIndex, 'asc']).draw();
				} else if (clickCount === 2) {
					table.order([columnIndex, 'desc']).draw();
				} else {
					table.order([]).draw();
					columnClicked = -1;
					clickCount = 0;
				}
			});
		});
	}
});

$(window).on('load', function () {
	$('table.js-tableSort01').each(function () {
		let _this = this;
		let $this = $(_this);
		let i = 0;
		$('tbody > tr', _this).each(function () {
			$(this).attr('data-index', i);
			i++;
		});

		$('.js-sort-rowspan1', _this).each(function () {
			let rowspan = $(this).attr('rowspan');
			$(this).attr('data-rowspan', rowspan);
		});

		$('td[data-column-win]', _this).each(function () {
			let win = $(this).data('column-win');
			let winNum = parseFloat(win);

			let html = $(this).html();
			let classColor = '';

			if (winNum <= 10 || isNaN(winNum)) {
				classColor = 'is-red';
			} else if (winNum > 10 && winNum <= 1000) {
				classColor = 'is-black';
			} else if (winNum > 1000) {
				classColor = 'is-blue';
			}

			let result = html.replace(
				win,
				'<span class="' + classColor + '">' + win + '</span>',
			);
			$(this).html(result);
		});

		const sortTable = function (name, type = 'asc') {
			var table = _this;
			var tbody = table.getElementsByTagName('tbody')[0];
			var rows, switching, i, x, y, shouldSwitch, isReset;
			switching = true;

			isReset = false;
			if (type === 'reset') {
				isReset = true;
				type = 'asc';
			}

			while (switching) {
				switching = false;
				rows = tbody.rows;

				for (i = 0; i < rows.length - 1; i++) {
					shouldSwitch = false;

					if (isReset) {
						x = $(rows[i]).data('index');
						y = $(rows[i + 1]).data('index');
					} else {
						x = $('td[data-column-' + name + ']', rows[i]).data(
							'column-' + name,
						);
						y = $('td[data-column-' + name + ']', rows[i + 1]).data(
							'column-' + name,
						);
					}

					x = parseFloat(x);
					if (isNaN(x)) {
						x = -1;
					}

					y = parseFloat(y);
					if (isNaN(y)) {
						y = -1;
					}

					let check;
					if (type === 'desc') {
						check = x < y;
					} else {
						check = x > y;
					}

					if (check) {
						shouldSwitch = true;
						break;
					}
				}

				if (shouldSwitch) {
					//console.log('shouldSwitch');
					rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
					switching = true;
				}
			}
		};

		$('[data-column]', _this).on('click', function () {
			let column = $(this);
			let name = column.data('column');
			let columns = $('[data-column-' + name + ']', _this);
			if (columns.length) {
				$('.js-sort-rowspan1', _this).each(function () {
					$(this).attr('rowspan', 1);
				});

				let isAsc = column.hasClass('sort-asc');
				let isDesc = column.hasClass('sort-desc');
				let isNo = !isAsc && !isDesc;

				$this.removeClass('sort-desc sort-asc');
				$('.sort-desc', $this).removeClass('sort-desc');
				$('.sort-asc', $this).removeClass('sort-asc');

				if (name == 'win') {
					if (isNo) {
						$this.addClass('sort-asc');
						column.addClass('sort-asc');
						sortTable(name, 'asc');
					} else if (isAsc) {
						$this.addClass('sort-desc');
						column.addClass('sort-desc');
						sortTable(name, 'desc');
					} else if (isDesc) {
						sortTable(name, 'reset');
						$('.js-sort-rowspan1', _this).each(function () {
							let rowspan = $(this).attr('data-rowspan');
							$(this).attr('rowspan', rowspan);
						});
					}
				} else {
					if (isNo) {
						$this.addClass('sort-desc');
						column.addClass('sort-desc');
						sortTable(name, 'desc');
					} else if (isDesc) {
						$this.addClass('sort-asc');
						column.addClass('sort-asc');
						sortTable(name, 'asc');
					} else if (isAsc) {
						sortTable(name, 'reset');
						$('.js-sort-rowspan1', _this).each(function () {
							let rowspan = $(this).attr('data-rowspan');
							$(this).attr('rowspan', rowspan);
						});
					}
				}
			}
		});
	});
});

$(window).on('load', function () {
	$('.js-tableHeadWrap').each(function () {
		const isObserver = typeof ResizeObserver === 'function';
		// 引数として指定された要素のoffsetTopを再帰的に計算して返す処理
		const getOffsetTop = function (elem) {
			let offsetTop = elem.offsetTop;
			if (elem.offsetParent) {
				offsetTop += getOffsetTop(elem.offsetParent);
			}
			return offsetTop;
		};

		let wrap = $(this);
		let wrapHeadFixed = $('.js-tableHeadFixed', this);
		let wrapHeadClone = $('.js-tableHeadClone', this);

		let table = $('table', wrapHeadFixed);
		let tableFixed = $('table', wrapHeadClone);
		// 出走比較用
    const isHikaku = document.querySelector('.p-hikaku_cho') != null;
    const scrollContent = document.querySelector('.nk23_c-table13__inner .scroll-content');

		if (table.length && tableFixed.length) {
			let parentTable = table.parent();

			let thead = $('thead', table);
			let theadClone = $('thead', tableFixed);
			let theadCreate = false;
			if (!theadClone.length) {
				theadClone = thead.clone();
				theadCreate = true;
			}

			let headCloneHeight = 0;
      if (isHikaku) {
        // 出走比較用にスタイル調整
				wrapHeadClone.css('position', 'relative');
        // 固定ヘッダーの高さを取得しておく
        let tableheadClone = document.querySelector(".js-tableHeadClone");
        headCloneHeight = tableheadClone.clientHeight;

				// 固定ヘッダーを自然な位置に調整する
				if (document.querySelector('#tableHeadCloneWrapper') == null) {
          var headCloneWrap = document.createElement("div");
          headCloneWrap.id = 'tableHeadCloneWrapper';
          headCloneWrap.style.position = 'absolute';
          headCloneWrap.style.overflow = 'hidden';
          headCloneWrap.style.width = '100%';

          var headCloneParent = tableheadClone.parentNode;
          headCloneParent.insertBefore(headCloneWrap, tableheadClone);
          headCloneWrap.appendChild(tableheadClone);
        }
      }

			// オリジナルのヘッダーと固定表示ヘッダーの列幅を同期させる処理
			let syncThead = function () {
				let theadHeight = thead.outerHeight();
				wrapHeadClone.css('top', theadHeight * -1 + 'px');
				let columnClone = $('th, td', theadClone);
				$('th, td', thead).each(function (index) {
					let clientRect = this.getBoundingClientRect();
					columnClone.eq(index).css('width', clientRect.width + 'px');
				});
			};

			// ヘッダーの表示状態を確認し、固定ヘッダーの表示/非表示を切り替える処理
			let checkThead = function () {
				let tableTop = getOffsetTop(table.get(0));
				let tableHeight = table.outerHeight();
				let theadHeight = thead.outerHeight();

				let posIn = tableTop + theadHeight;
				let posOut = tableTop + tableHeight - theadHeight;
				let scrollTop = $(window).scrollTop();

				// 出走比較用の処理
        let scrollContentTop = 0;
        if (isHikaku) {
					// 表内でどれだけ縦にスクロールされたかを取得（スクロールはjavascriptで実現しているため、transform:translate3dの第二引数から直接取る）
          scrollContentTop = (scrollContent.style.transform.replace("translate3d(","").replace(")","") || "0px, 0px, 0px").split(", ")[1].replace("px","");
          scrollContentTop = scrollContentTop * -1;
        }

				// スクロール位置がtheadの表示範囲外にあるかどうか
				if (scrollTop >= posIn && scrollTop <= posOut) {
					// 範囲外の場合、固定ヘッダーを表示する
					wrap.addClass('show-fixed');
					wrapHeadClone.addClass('show');
					wrapHeadClone.css('top', 0);
				} else if (isHikaku && (headCloneHeight < scrollContentTop)) {
					// 出走比較の場合、theadの高さ < 表内の縦スクロール量の場合に固定ヘッダーを表示する
          wrap.addClass('show-fixed');
          wrapHeadClone.addClass('show');
					wrapHeadClone.css('top', 0);
        } else {
					// 範囲内の場合、固定ヘッダーは非表示
					wrap.removeClass('show-fixed');
					wrapHeadClone.removeClass('show');
					wrapHeadClone.css('top', theadHeight * -1 + 'px');
				}
			};

			parentTable.css('position', 'relative');

			if (theadCreate) {
				theadClone.addClass('thead-clone');
				tableFixed.append(theadClone);
			} else {
				theadClone.addClass('thead-clone thead-clone-current');
			}

			syncThead();
			checkThead();

			$(window).on('scroll', function () {
				checkThead();
			});
			// 出走比較の場合
      if (isHikaku) {
        // テーブルスクロール時にもイベントを発火させる
        // テーブル内スクロールはjavascriptを用いている関係で通常のscrollイベントが発生しないため、MutationObserverを使う
        var observer = new MutationObserver(function (mutations) {
          mutations.forEach(function (mutation) {
            // 変更を検知した場合の処理
            if (mutation.attributeName === 'style') checkThead();
          });
        });
        // 対象要素の属性を監視対象とする
        var config = { attributes: true };

        observer.observe(scrollContent, config);
      }

			wrapHeadFixed.on('scrollbar-event', function (ev, status) {
				wrapHeadClone.css('left', status.offset.x * -1);
			});

			if (isObserver) {
				let elemWidth = 0;
				new ResizeObserver(function (entries) {
					for (const entry of entries) {
						if (entry.contentRect.width !== elemWidth) {
							elemWidth = entry.contentRect.width;
							syncThead();
						}
					}
				}).observe(thead.get(0));
			} else {
				$(window).on('resize-x', function () {
					syncThead();
				});
			}
		}
	});
});

$(window).on('load', function () {
	let checkAllElements = $('.js-tableCheckAll');
	for (let i = 0; i < checkAllElements.length; i++) {
		let wrap = checkAllElements.get(i);

		const getDataColElem = function (input) {
			let dataColElem = $(input).parents('[data-col]');
			if (dataColElem.length && dataColElem.data('col')) {
				return dataColElem.data('col');
			}
			return false;
		};

		$('.js-checkAll input', wrap).on('change', function () {
			let dataCol = getDataColElem(this);
			if (false !== dataCol) {
				let checkAllOther = $(
					'[data-col="' + dataCol + '"] .js-checkAll input',
					wrap,
				).not(this);
				let singleCols = $(
					'[data-col="' + dataCol + '"] .js-checkSingle input',
					wrap,
				);
				if (singleCols.length) {
					if (this.checked) {
						singleCols.prop('checked', true);
						checkAllOther.prop('checked', true);
					} else {
						singleCols.prop('checked', false);
						checkAllOther.not(this).prop('checked', false);
					}
				}
			}
		});

		$('.js-checkSingle input', wrap).on('change', function () {
			let dataCol = getDataColElem(this);
			if (false !== dataCol) {
				let checkAll = $(
					'[data-col="' + dataCol + '"] .js-checkAll input',
					wrap,
				);
				if (checkAll.length) {
					let check = $('.js-checkSingle input', wrap);
					let checked = $('.js-checkSingle input:checked', wrap);
					if (check.length === checked.length) {
						checkAll.prop('checked', true);
						$('.js-checkAll-fixed1 input[type=checkbox]').length && $('.js-checkAll-fixed1 input[type=checkbox]').addClass('is-checked').removeClass('no-checked').attr('checked', true)
					} else {
						checkAll.prop('checked', false);
						$('.js-checkAll-fixed1 input[type=checkbox]').length && $('.js-checkAll-fixed1 input[type=checkbox]').removeClass('is-checked').addClass('no-checked').attr('checked', false)
					}
				}
			}
		});
	}

	/* Todo: backup js-tableCheckAll
		$('.js-tableCheckAll').each(function () {
				let _this = $(this);
				$('.js-checkAll', this).each(function () {
						$('input', this).on('click', function () {
								let checkedStatus = this.checked;
								let type = $(this).closest('th').data('col');

								$(`td[data-col=${type}] .js-checkSingle input`, _this).each(
										function () {
												this.checked = checkedStatus;
										},
								);
						});
				});

				$('.js-checkSingle input', _this).on('click', function () {
						let type = $(this).closest('td').data('col');
						let optionSingle = $(
								`tbody td[data-col=${type}] .js-checkSingle input`,
								_this,
						);
						let optionAll = $(`thead th[data-col=${type}] .js-checkAll input`, _this);

						if (optionSingle.is(':checked')) {
								let isAllChecked = 0;
								optionSingle.each(function () {
										if (!this.checked) {
												isAllChecked = 1;
										}
								});

								isAllChecked == 0
										? optionAll.prop('checked', true)
										: optionAll.prop('checked', false);
						} else {
								optionAll.prop('checked', false);
						}
				});
		});
		*/
});

$(function () {
	$('.js-table-clear').on('click', function (ev) {
		ev.stopPropagation();
		ev.preventDefault();
		let table = $($(this).data('table'));
		if (table.length) {
			$('.nk23_c-checkbox__label input', table).prop('checked', false);
			$('.js-is-circle.is-circle', table).removeClass('is-circle');
			$('.nk23_c-btn06.js-btn6', table).removeClass(
				'is-active1 is-active2 is-active3 is-active4 is-active5',
			);
			$('.nk23_c-btn06.onBtn', table).removeClass(
				'is-active1 is-active2 is-active3 is-active4 is-active5',
			);
			$('td.nk23_u-text16.js-count:not(.preview)').html(0);
			$('td.nk23_u-text16.js-count:not(.preview)').removeClass('nk23_u-bg-color35');
			$('td.nk23_u-text16.js-count:not(.preview)').removeClass('nk23_u-bg-color36');
			$('td.nk23_u-text16.js-count:not(.preview)').removeClass('nk23_u-bg-color37');
		}
	});
});

$(function () {
	$('.is-check').on('click', function () {
		let _checknum = Number($(this).data('num'));
		if (
			$('.is-check-box' + _checknum + '.cb1 input').prop('checked') == true &&
			$('.is-check-box' + _checknum + '.cb2 input').prop('checked') == true &&
			$('.is-check-box' + _checknum + '.cb3 input').prop('checked') == true
		) {
			$('.is-check-box' + _checknum + ' input').prop('checked', false);
		} else {
			$('.is-check-box' + _checknum + ' input').prop('checked', true);
		}
	});
	$('.is-frame-check').on('click', function () {
		let _checkindex = $(this).index();
		let _checknum1;
		if (_checkindex == 0) {
			_checknum1 = Number($(this).next().data('num'));
		} else {
			_checknum1 = Number($(this).prev().data('num'));
		}
		let _checknum2 = _checknum1 + 1;
		if (
			$('.is-check-box' + _checknum1 + '.cb1 input').prop('checked') == true &&
			$('.is-check-box' + _checknum1 + '.cb2 input').prop('checked') == true &&
			$('.is-check-box' + _checknum1 + '.cb3 input').prop('checked') == true &&
			$('.is-check-box' + _checknum2 + '.cb1 input').prop('checked') == true &&
			$('.is-check-box' + _checknum2 + '.cb2 input').prop('checked') == true &&
			$('.is-check-box' + _checknum2 + '.cb3 input').prop('checked') == true
		) {
			$('.is-check-box' + _checknum1 + ' input').prop('checked', false);
			$('.is-check-box' + _checknum2 + ' input').prop('checked', false);
		} else {
			$('.is-check-box' + _checknum1 + ' input').prop('checked', true);
			$('.is-check-box' + _checknum2 + ' input').prop('checked', true);
		}
	});
});

$(window).on('load', () => {
	const table12 = $('.nk23_c-table12__table');
	const table12Icon = $('.nk23_c-table12__icon');

	if (table12.length) {
		if ($('.nk23_c-table12__table').innerHeight() < 300) {
			table12Icon &&
				table12Icon.css({
					top: '50%',
					transform: 'translate(-50%, -50%)',
				});
		}
	}

	const radiosTable19 = $('.js-radio-table19');
	const afterActiveHtml = `<span class="nk23_c-table19__after"></span>`;
	const radioTable = $('.js-radio-tb19');

	if (radioTable.length) {
		radioTable.each(function () {
			const _thisTable = $(this);
			const tr = _thisTable.find('tbody tr');

			tr.each(function () {
				const _thisTr = $(this);

				_thisTr.on('click', function () {
					$('.nk23_c-table19__after').length &&
						$('.nk23_c-table19__after').remove();
					_thisTable.find('input[type=radio]').prop('checked', false);
					_thisTable.find('tr').removeClass('is-active');
					_thisTr
						.addClass('is-active')
						.find('input[type=radio]')
						.prop('checked', true);

					_thisTr.find('.nk23_c-checkbox2').append(afterActiveHtml);

					if (_thisTr.innerWidth() > 0 && _thisTr.innerHeight() > 0) {
						_thisTr.find('.nk23_c-checkbox2').css({
							height: _thisTr.innerHeight() - 9,
						});

						$('.nk23_c-table19__after').css({
							width: _thisTr.innerWidth(),
							height: _thisTr.innerHeight() + 1,
						});
					}
				});
			});
		});
	}

	if (radiosTable19.length) {
		radiosTable19.each(function () {
			$(this).prop('checked') && handleActiveTr($(this));

			$(this).on('change', function () {
				handleActiveTr($(this));
			});
		});
	}

	function handleActiveTr(_this) {
		_this.parents('table').find('tr').removeClass('is-active');
		_this.parents('tr').addClass('is-active');
		_this
			.parents('tr.is-active')
			.find('.nk23_c-checkbox2')
			.append(afterActiveHtml);
		_this
			.parents('tr.is-active')
			.find('.nk23_c-checkbox2')
			.css({
				height: _this.parents('tr.is-active').innerHeight() - 9,
			});

		if (
			_this.parents('tr.is-active').innerWidth() > 0 &&
			_this.parents('tr.is-active').innerHeight() > 0
		) {
			$('.nk23_c-table19__after').css({
				width: _this.parents('tr.is-active').innerWidth(),
				height: _this.parents('tr.is-active').innerHeight() + 1,
			});
		}
	}

	// fixed TOP table custom scrollbar
	const jsFixeds = $('.js-fixed');
	const jsFixed1s = $('.js-fixed1');

	function handleFixedTopTable(ele, table, isFixed) {
		if (isFixed) {
			ele.addClass('is-active');
			table.addClass('is-hide');
		} else {
			ele.removeClass('is-active');
			table.removeClass('is-hide');
		}
	}

	if (jsFixeds.length) {
		jsFixeds.each(function () {
			const ele = $(this).find('.js-fixed-ele');
			const title = $(this).find('.js-fixed-title');
			const table = $(this).find('.js-fixed-table');
			const scrollbar = $(this).find('.custom-scrollbar-inner');

			if (ele.length && table.length) {
				$(window).on('scroll', function () {
					let hSticky = 0;

					if ($('.js-sticky').length) {
						hSticky = $('.js-sticky').innerHeight();
					}

					const distance = table.offset().top + table.innerHeight() - title.innerHeight() - hSticky
					const distance2 = table.offset().top + (title.innerHeight() > hSticky ? title.innerHeight() - hSticky : 0)


					if ($(window).scrollTop() >= distance2) {
						if ($(window).scrollTop() > distance) {
							handleFixedTopTable(ele, table, false);

						} else {
							handleFixedTopTable(ele, table, true);
						}

					} else {
						handleFixedTopTable(ele, table, false);
					}
				});

				scrollbar.on('scroll', function () {
					ele.scrollLeft($(this).scrollLeft());
				});

				$(window).on('resize', function () {
					if ($(window).outerWidth() > 767) {
						handleFixedTopTable(ele, table, false);
					}
				});

				$('.js-tab2-btn, .js-switch1-btn').on('click', function () {
					handleFixedTopTable(ele, table, false);
					if ($(this).hasClass('js-tab2-btn')) {
						if ($(this).data('tab') === 'tab2') {
							$('.js-table-clear').removeClass('is-disable');
						} else if ($(this).data('tab') === 'tab1') {
							$('.js-table-clear').addClass('is-disable');
						}
					}
				});
			}
		});
	}

	if (jsFixed1s.length) {
		jsFixed1s.each(function () {
			const _this = $(this)
			const ele = $(this).find('.js-fixed1-ele');
			const elePC = $(this).find('.js-fixed1-ele-pc');
			const title = $(this).find('.js-fixed1-title');
			const table = $(this).find('.js-fixed1-table');
			const inputCheckallFixed = $(this).find('.js-checkAll-fixed1 input[type=checkbox]');
			const inputCheckall = $(this).find('.js-checkAll input[type=checkbox]');
			const scrollbar = $(this).find('.custom-scrollbar-inner');

			if (ele.length && table.length) {
				ele.css('top', 0);

				$(window).on('scroll', () => {
					let hThead = 0

					if (table.find('thead').innerHeight() > title.innerHeight()) {
						hThead = table.find('thead').innerHeight()

					} else {
						hThead = title.innerHeight()
					}

					if (elePC.length) {
						if ($(window).outerWidth() > 767) {
							// 変数disが宣言されていないためエラーになる。
							// が、意図した挙動ではないため使用せず、returnで処理を抜ける。
							return;
							// dis = table.offset().top;
							// elePC.find('table').css({
							// 	width: $(this).innerWidth(),
							// 	'max-width': $(this).innerWidth(),
							// 	'min-width': $(this).innerWidth(),
							// })
						}
					}

					const distance = table.offset().top + table.innerHeight() - hThead
					const distance2 = table.offset().top + hThead

					if ($(window).scrollTop() >= distance2) {
						if ($(window).scrollTop() > distance) {
							handleFixedTopTable(ele, table, false);

						} else {
							handleFixedTopTable(ele, table, true);
						}

					} else {
						handleFixedTopTable(ele, table, false);
					}
				});

				scrollbar.on('scroll', function () {
					ele.scrollLeft($(this).scrollLeft());
				});

				$(window).on('resize', () => {
					if ($(window).outerWidth() > 767) {
						handleFixedTopTable(ele, table, false);
						ele.find('thead td, thead th').removeAttr('style')
					}
				});

				$('.js-tab2-btn, .js-switch1-btn').on('click', () => {
					handleFixedTopTable(ele, table, false);
				});

				inputCheckallFixed.on('change', function () {

					if (inputCheckallFixed.prop('checked')) {
						inputCheckallFixed.addClass('is-checked').removeClass('no-checked')
						_this.find('input[type=checkbox]').each(function () {
							$(this).prop('checked', true)
						})

					} else {
						inputCheckallFixed.removeClass('is-checked').addClass('no-checked')
						_this.find('input[type=checkbox]').each(function () {
							$(this).prop('checked', false)
						})
					}
				})

				inputCheckall.on('change', function () {
					inputCheckall.prop('checked') ? inputCheckallFixed.addClass('is-checked').removeClass('no-checked').attr('checked', true) : inputCheckallFixed.removeClass('is-checked').addClass('no-checked').attr('checked', false)
				})

				// Handle width th, td off table13
				function handleWidthTdFixed() {
					let arrW1 = []
					let arrW2 = []

					table.find('thead tr:nth-child(2) td').each(function () {
						arrW1.push($(this).innerWidth())
					})

					table.find('thead tr:nth-child(1) th').each(function () {
						arrW2.push($(this).innerWidth())
					})

					arrW1.map(function (width, idx) {
						ele.find(`thead tr:nth-child(2) td:nth-child(${idx + 1})`).css('min-width', width + 1)
					})

					arrW2.map(function (width, idx) {
						ele.find(`thead tr:nth-child(1) th:nth-child(${idx + 1})`).css('min-width', width + 1)
					})

					arrW1 = []
					arrW2 = []

					if ($(window).outerWidth() > 767) {
						ele.find('thead td, thead th').removeAttr('style')
					}
				}

				// handleWidthTdFixed()

				$(window).on('resize', function () {
					// handleWidthTdFixed()
				})
			}
		});
	}

	if ($('.js-scroll-fixed-col .is-txtV').length) {

		if (detectIos()) {
			$('.js-scroll-fixed-col .is-txtV').each(function () {
				if ($(this).text().trim().length > 7) {
					$(this).addClass('is-ios')
					$(this).parents('td').addClass('is-ios')

					if ($(this).find('span').length) {
						$(this).parents('td').addClass('has-span')
					}
				}
			})

		}
	}
});

$(function () {
	$('.bg-over tr')
		.on('mouseover', function () {
			$(this).addClass('tb-over');
		})
		.on('mouseout', function () {
			$(this).removeClass('tb-over');
		});

		function textTrim() {
			// テキストをトリミングする要素
			let selector = document.querySelectorAll('[data-texttrim]');

			// 制限する文字数
			let wordCount = 6;

			// 文末に追加したい文字
			let clamp = '︙';

			for (let i = 0; i < selector.length; i++) {
				// 文字数を超えたら
				if (selector[i].innerText.length > wordCount) {
					let str = selector[i].innerText // 文字数を取得

					// 取消のときの判定
					if( hasSpaces(selector[i].innerText) || hasZenkakuSpaces(selector[i].innerText) ) {
						str = str.substr(0, wordCount);
					}else {
						str = str.substr(0, (wordCount - 1)); // 1文字削る
					}
					selector[i].innerText = str + clamp; // 文末にテキストを足す
				}
			}
		}

		// 半角空白があるか
		function hasSpaces(str) {
			if (str.indexOf(' ') !== -1) {
				return true
			} else {
				return false
			}
		}

		// 全角空白があるか
		function hasZenkakuSpaces(str) {
			if (str.indexOf('　') !== -1) {
				return true
			} else {
				return false
			}
		}

		textTrim();
});
