import $ from 'jquery';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

const wind = $(window);

wind.on('load', () => {
  const calendar = $('.js-calendar1')
  const slide03 = $('.js-slide03');

  if (slide03.length) {
    slide03.each(function () {
      const _this = $(this);

      new Swiper(_this[0], {
        allowTouchMove: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
    });
  }

  if (calendar.length) {
    calendar.each(function () {
      const _thisCalendar = $(this)
      const item = _thisCalendar.find('th.is-active')

      if (item.length) {
        item.each(function () {
          const _thisItem = $(this)
          const idx = _thisItem.index() + 1

          _thisItem.parents('.js-calendar1').find(`tbody td:nth-child(${idx})`).addClass('is-active')
        })
      }
    })
  }
})

let _resizeFlag = false;
let _calendarLeft;
wind.on('load resize', (e) => {
  let _calendarTable = $('.p-calendar .js-scroll-position');
  if (_calendarTable.length) {
    _calendarTable.each(function () {
			let orientation = matchMedia("(orientation: portrait)");
			if( orientation.matches ) {
        if (wind.outerWidth() <= 767) {
          if (!_resizeFlag) {
            _calendarLeft = $(this).find('.nk23_c-table21__table thead tr:first-child th.is-active').offset().left;
						const _firstblock = Number($(this).find('.nk23_c-table21__table thead tr:first-child th:first-child').outerWidth());
						const _secondblock = Number($(this).find('.nk23_c-table21__table thead tr:first-child th:nth-of-type(2)').outerWidth());
						const _calendarLeftSize = _calendarLeft - _firstblock - _secondblock - 8;
						//console.log(_firstblock+"/"+_secondblock+"/"+_calendarLeftSize);
						_calendarTable.scrollLeft(_calendarLeftSize);
            _resizeFlag = true;
          }
					e.stopPropagation();
        } else {
          _calendarTable.scrollLeft(0);
          _resizeFlag = false;
					e.stopPropagation();
        }
      } else {
        _calendarTable.scrollLeft(0);
        _resizeFlag = false;
      }
    });
  }
})
