import $ from 'jquery';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

import { detectIos, detectAndroid, bodyFix, bodyFixReset } from './_Functions.js';

$(function () {
	const body = $('body');
	const wind = $(window);
	const bodyHTML = $('body, html');
	const btn = $('.js-menu');
	const headerSlide = $('.js-header-slide');
	const content = $('.nk23_c-header__content');
	const btnAccor = $('.js-menu-accor');
	const accorContent = $('.js-menu-accorcontent');
	const placeHolderRadios = $('.js-placeholder-radios')
	const placeHolderInput = $('.js-placeholder-input')

	let scrollPosi;
	let slider1;

	if (placeHolderRadios.length) {
		const radios = placeHolderRadios.parents('form').find('input[type=radio]')

		radios.on('change', function () {
			placeHolderInput.val('')

			if ($(this).data('radio') === 'レース名') {
				placeHolderInput.length && placeHolderInput.attr('placeholder', '全角文字 / 部分一致検索')

			} else {
				placeHolderInput.length && placeHolderInput.attr('placeholder', '全角カタカナ / 前方一致検索')
			}
		})
	}

	if (btn.length) {
		btn.on('click', function () {
			bodyFix();
			content.addClass('is-open');
		});
	}

	$('.nk23_c-header__content').on('click', function (ev) {
		if ($(ev.target).is('.nk23_c-header__content')) {
			resetHeader(content)
		}
	})

	let widthScrollbar = window.innerWidth - document.documentElement.clientWidth;
	let space = 50

	if (detectIos() || detectAndroid()) {
		space = 120
		widthScrollbar = 0
	}

	function handlePositionChildMenu() {
		if (wind.outerWidth() > 767) {

			if ($('.nk23_c-header__middle .nk23_c-header__child').length) {
				const menuSubChild = $('.nk23_c-header__middle .nk23_c-header__child')

				menuSubChild.removeAttr('style')

				menuSubChild.each(function () {
					const dis = $(this).outerWidth() + $(this).offset().left

					if ($(this).outerWidth() + $(this).offset().left > wind.outerWidth() - widthScrollbar) {
						const disOver = dis - wind.outerWidth() + space

						$(this).css('left', -disOver)

					} else {
						if (detectIos() || detectAndroid()) {
							menuSubChild.last().css('left', -118)
						} else {
							$(this).css('left', -13)
						}
					}
				})

			}
		} else {
			$('.nk23_c-header__middle .nk23_c-header__child').removeAttr('style')
		}
	}

	handlePositionChildMenu()


	function handleCreateSlideMenu() {
		if (headerSlide.length) {

			if (wind.outerWidth() < 768) {
				const idxSlide = $('.js-header-slide .nk23_c-header__menu__inner .nk23_c-header__menu__item.is-active').index()

				slider1 = new Swiper('.js-header-slide', {
					freeMode: true,
					slidesPerView: 'auto',
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
				});

				if (slider1) {
					setTimeout(() => {
						if (!slider1.destroyed) {
							slider1.slideToLoop(idxSlide, 0);
						}
					}, 500);
				}

			} else {
				if (slider1) {
					slider1.destroy();
				}
			}
		}
	}

	handleCreateSlideMenu()

	$(window).on('resize', function () {
		handlePositionChildMenu();
		handleCreateSlideMenu();
	})

	if (wind.outerWidth() > 767) resetHeader(content)

	if (btnAccor.length) {
		btnAccor.on('click', function () {
			$(this).toggleClass('is-active').next(accorContent).slideToggle();
		});
	}

	$(window).on('pageshow', function (event) {
		if (event.originalEvent.persisted) {
			content.removeClass('is-open')

			if (!$('.js-modal1-modal.is-active').length) {
				body.removeClass('is-fixed')
			}
		}
	});

	function resetHeader(content) {
		content.removeClass('is-open');

		if (!$('.js-modal1-modal.is-active').length) {
			bodyFixReset();
		}
	}
})


