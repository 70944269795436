import $ from 'jquery';

window.sp = function () {
    return window.innerWidth < 768;
}

window.isObserver = typeof ResizeObserver === 'function';
window.resizeXWidth = window.innerWidth;
window.eventResizeX = function () {
    if (window.resizeXWidth !== window.innerWidth) {
        window.resizeXWidth = window.innerWidth;
        $(window).trigger('resize-x');
        //console.log('resize-event');
    }
}

window.scrollBarWidth = 0;
window.getScrollBarWidth = function () {
    let div = document.createElement('div');
    div.style.overflow = 'scroll';
    div.style.width = '100px';
    div.style.height = '100px';
    div.style.visibility = 'hidden';
    document.body.appendChild(div);
    window.scrollBarWidth = div.offsetWidth - div.clientWidth;
    document.body.removeChild(div);
}

$(window).on('load', function () {
    $(window).on('orientationchange', window.eventResizeX);
    if (window.isObserver) {
        new ResizeObserver(window.eventResizeX).observe(document.body);
    } else {
        $(window).on('resize', window.eventResizeX);
    }

    window.getScrollBarWidth();
    window.resizeXWidth = window.innerWidth;
    $(window).trigger('resize-x');
});
