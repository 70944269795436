import $ from 'jquery';

$(function () {
  // ログインボタンをクリックしたときの処理
  $('.js-login-moda-btn').on('click', function (e) {
    e.preventDefault();

    $('.login-modal-content').fadeIn();
    $('.login-modal-overlay').fadeIn();
  });

  // クローズボタンをクリックしたときの処理
  $('.login-modal-close').on('click', function (e) {
    e.preventDefault();

    $('.login-modal-content').fadeOut();
    $('.login-modal-overlay').fadeOut();
  });
});

const setFillHeight = () => {
  const modalVh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--modalVh', `${modalVh}px`);
};

// 画面のサイズ変動があった時に高さを再計算する
window.addEventListener('resize', setFillHeight);

// 初期化
setFillHeight();
