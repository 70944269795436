import $ from 'jquery';

// var Isotope = require('isotope-layout');

// var iso = new Isotope('.js-filter1', {
//   itemSelector: '.js-filter1-item',
// });

// $('.js-filter1-btn').on('click', function () {
//   const data = $(this).data('filter');

//   iso.arrange({
//     filter: data,
//   });

//   $(this)
//     .parents('.js-filter1-btns')
//     .find('.js-filter1-btn')
//     .removeClass('is-active');
//   $(this).addClass('is-active');
// });

$(function () {
  $(document).on('click', '.is-disable', function(){
    return false;
  });
});
