import $ from 'jquery';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

$(function () {
	let slide01 = $('.js-slide01');
	if (slide01.length) {
		let listSlide = [];

		function slide01CallBack() {
			let i = 0;
			slide01.each(function () {
				let _this = $(this);
				const slideLength = _this.children().children().length;
				const options = {
					slidesPerView: 1.16999,
					centeredSlides: true,
					loop: true,
					autoplay: {
						delay: 5000,
						pauseOnMouseEnter: true,
						disableOnInteraction: false,
					},
					spaceBetween: 0,
					pagination: {
						el: $('.swiper-pagination', this).get(0),
						clickable: true,
					},
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
				};
				if (slideLength > 1) {
					if ($(window).outerWidth() < 768) {
						options.slidesPerView = 1.1958;
					}

					listSlide[i] = new Swiper(_this[0], options);

					const btnNext = _this.find('.swiper-button-next');
					const btnPrev = _this.find('.swiper-button-prev');

					if ($(window).outerWidth() > 767) {
						btnNext.on('mousemove', () => {
							_this.find('.swiper-slide-next').addClass('is-hover');
						});

						btnNext.on('mouseleave click', () => {
							_this.find('.swiper-slide').removeClass('is-hover');
						});

						btnPrev.on('mousemove', () => {
							_this.find('.swiper-slide-prev').addClass('is-hover');
						});

						btnPrev.on('mouseleave click', () => {
							_this.find('.swiper-slide').removeClass('is-hover');
						});
					}
				}
				i++;
			});
		}

		slide01CallBack();
		$(window).on('resize', function (e) {
			listSlide.filter(function(value, index) {
				//バナーの画像が一つで空きにっなている要素は除外する
			  return index !== undefined;
			}).forEach(function(value) {
			  value.destroy();
			});
			slide01CallBack();
		});
	}

	const slide02 = $('.js-slide02');

	if (slide02.length) {
		slide02.each(function () {
			const _this = $(this);

			new Swiper(_this[0], {
				loop: true,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
			});
		});
	}


	const slide04 = $('.js-slide04');

	if (slide04.length) {
		const breakpoint = 768;

		const isHorizontal = function() {
			const isPortrait = window.matchMedia('(orientation: portrait)');
			// トップページのPCレイアウトの条件と同じで評価する
			// screen and (min-width: 768px)
			// または
			// screen and (min-width: 768px) and (orientation: portrait)
			// または
			// screen and (orientation: landscape)
			if(window.innerWidth > breakpoint || (window.innerWidth > breakpoint && isPortrait.matches) || !isPortrait.matches){
				return true;
			}else{
				return false;
			}
		}
		slide04.each(function () {
			const _this = $(this);
			const slideLength = _this.children().children().length;

			if (slideLength > 1) {
				const swiperOption = {
						//横
						"horizontal" : {
							loop: true,
							speed: 300,
							effect: 'creative',
							autoplay: {
								delay: 5000,
								pauseOnMouseEnter: true,
								disableOnInteraction: false,
							},
							loopedSlides: 2,
							creativeEffect: {
								prev: {
									scale: 1.66,
									opacity: 0,
									translate: [-28, -46, -800],
								},
								next: {
									scale: 1.66,
									opacity: 0.5,
									translate: [16, 16, -800],
								},
							},
							pagination: {
								el: $('.swiper-pagination', this).get(0),
								clickable: true,
							},
							navigation: {
								nextEl: '.swiper-btn-next',
								prevEl: '.swiper-btn-prev',
							},
						},
						//縦
						"vertical" : {
							slidesPerView: 1.12,
							centeredSlides: true,
							loop: true,
							spaceBetween: 0,
							speed: 300,
							autoplay: {
								delay: 5000,
							},
						}
				}
				let swiper;
				if (isHorizontal()) {
					swiper = new Swiper(_this[0], swiperOption.horizontal);
				} else {
					swiper = new Swiper(_this[0], swiperOption.vertical);
				}
				$(window).on("resize", function() {
					const option = isHorizontal() ? swiperOption.horizontal : swiperOption.vertical;
					//Swiperをリロードする
					swiper.destroy(true, true);
					swiper = new Swiper(_this[0], option);
				})
			} else {
				_this.find('.nk23_c-slide04__listbtn1.pc').hide();
				new Swiper(_this[0], {
					loop: false,
				});
			}
		});
	}
});
