import $ from 'jquery';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

import { setTimeout } from 'core-js';
import changeWidthHover, {
	checkFixedFooter,
	handleFixedScrolled,
} from './_Functions.js';
import activeMatchHeight from './_MatchHeight.js';

const wind = $(window);

// タブの設定
wind.on('load', () => {
	/**
	 * タブを操作するための関数。
	 * タブボタンがクリックされたときに、アクティブなタブを切り替え、該当するタブコンテンツを表示します。
	 * タブの移動は滑らかなアニメーションで行われ、モバイルとデスクトップの両方に対応しています。
	 *
	 * @param {string} Tab - タブの親要素を指定するセレクター文字列
	 * @param {string} TabBtn - タブボタンを指定するセレクター文字列
	 * @param {string} dataTabContent - タブコンテンツを識別するためのdata属性名
	 */
	const activeTab = (Tab, TabBtn, dataTabContent) => {
		$(Tab).each(function () {
			const _thisTab = $(this);
			const tabBtn = _thisTab.find(TabBtn);
			const tabContent = _thisTab.find(`[${dataTabContent}]`);
			const tabLine = _thisTab.find('.js-tab-line');

			if (!tabBtn.length) return;

			/**
			 * タブの下線を動かすための関数。
			 * 与えられたfromPositionからtoPositionまでタブの下線を移動します。
			 * 移動が完了したら、下線のopacityを0に設定して下線を非表示にします。
			 *
			 * @param {number} fromPosition - 下線の開始位置
			 * @param {number} toPosition - 下線の終了位置
			 */
			const moveTabLine = (fromPosition, toPosition) => {
				tabLine.css({
					'left': `${fromPosition}px`,
					'opacity': 1,
				});

				setTimeout(() => {
					tabLine.css({
						'left': `${toPosition}px`,
						'transition': 'left 0.3s linear, width 0.3s linear',
					});
				}, 0);

				tabLine.one("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd", function () {
					setTimeout(() => {
						$(this).css('opacity', 0);
					}, 100);
				});
			}

			/**
			 * タブクリック時のハンドラ関数。
			 * クリックされたタブをアクティブに設定し、該当するタブコンテンツを表示します。
			 * また、タブの下線を動かすためにmoveTabLine関数を呼び出します。
			 */
			const handleTabClick = function () {

				handleFixedScrolled();
				const $this = $(this);
				const windowWidth = $(window).outerWidth();
				const paddingLeft = 8;
				let activeTabOffset;

				tabBtn.each(function () {
					if ($(this).hasClass('is-active')) {
						activeTabOffset = windowWidth < 768 ? $(this).offset().left - paddingLeft : $(this).parent().position().left;
						return false;
					}
				});

				setTimeout(() => {
					tabLine.css('width', $(this).outerWidth());
				}, 0);

				const leftPosition = windowWidth < 768 ? $this.offset().left : $this.parent().position().left;
				moveTabLine(activeTabOffset, leftPosition - (windowWidth < 768 ? paddingLeft : 0));

				tabContent && tabContent.removeClass('is-active');
				tabBtn.removeClass('is-active');

				$this.addClass('is-active').parents(Tab).find(`[${dataTabContent}=${$this.data('tab')}]`).addClass('is-active');

				// Add strix St 23.11.15
				// document.querySelector('body').classList.add('is-loading');

				setTimeout(function () {
					activeMatchHeight();
				}, 10);
				// Add strix End 23.11.15
				changeWidthHover('.nk23_c-tab4__list__text', 4);
				checkFixedFooter();
			}

			tabBtn.on('click', handleTabClick);
		});
	};

	for (let i = 1; i < 8; i++) {
		if ($(`.js-tab${i}`).length) {
			if (i === 1) {
				activeTab('.js-tab1', '.js-tab1-btn', 'data-tab-content');
			} else {
				activeTab(`.js-tab${i}`, `.js-tab${i}-btn`, `data-tab${i}-content`);
			}
		}
	}

	changeWidthHover('.nk23_c-tab4__list__text', 4);

	/**
	 * アクティブなタブ下のライン（`tabLine2`）の幅と位置を調整します。
	 *
	 * @param {jQuery} _thisTab - 現在のタブのjQueryオブジェクト。
	 * @param {jQuery} tabLine2 - 調整するラインのjQueryオブジェクト。
	 * @param {number} idxActive - アクティブな項目のインデックス。
	 * @param {jQuery} thisItem - 現在のタブ項目のjQueryオブジェクト。
	 */
	function handleActiveLineTab(_thisTab, tabLine2, idxActive, thisItem) {
		let widthActive = 0;
		let spaceActive = 4;

		if (
			tabLine2.parents('.nk23_c-block06').length ||
			tabLine2.parents('.nk23_c-tab6').length
		) {
			$(window).outerWidth() < 768 ? (spaceActive = 4) : (spaceActive = 23);
		}

		tabLine2.css('width', thisItem.innerWidth());

		if (idxActive === 0) {
			$(window).outerWidth() < 768
				? tabLine2.css('left', spaceActive)
				: tabLine2.css('left', spaceActive);
		} else {
			for (let i = 0; i < idxActive; i++) {
				widthActive += _thisTab
					.find(`.js-line-item:nth-child(${i + 1})`)
					.outerWidth();
			}

			if (
				tabLine2.parents('.nk23_c-block06').length ||
				tabLine2.parents('.nk23_c-tab6').length
			) {
				if ($(window).outerWidth() < 768) {
					tabLine2.css('left', widthActive + spaceActive);
				} else {
					tabLine2.css('left', widthActive + spaceActive + 1);
				}
			} else {
				if ($(window).outerWidth() < 768) {
					tabLine2.css('left', widthActive + spaceActive + 1);
				} else {
					tabLine2.css('left', widthActive + spaceActive);
				}
			}
		}
	}

	/**
	 * 各タブのアクティブラインを動的に制御します。アクティブなタブが変更されたときに
	 * ラインの幅と位置が更新され、適切な背景クラスが付与/削除されます。
	 */
	function handleCustomLineTab() {
		const jsTabLines = $('.js-tab-lines');

		if (jsTabLines.length) {
			jsTabLines.each(function () {
				const _thisTab = $(this);
				const tabLine2 = _thisTab.find('.js-tab-line1');
				const items = _thisTab.find('.js-line-item');
				const activeItem = _thisTab.find('.is-active');

				if (activeItem.innerWidth() > 0) {
					tabLine2.css({
						width: activeItem.innerWidth(),
						transition: 'none',
					});
					setTimeout(() => {
						tabLine2.css({
							transition:
								'all .6s cubic-bezier(.33,1,.68,1), background-color .2s linear',
						});
					}, 0);
				}

				if (items.length) {
					items.each(function () {
						let idxActive = 0;
						let _thisItem = $(this);

						if ($(this).find('.is-active').length) {
							idxActive = $(this).index();
							handleActiveLineTab(_thisTab, tabLine2, idxActive, _thisItem);
						}
						$(this)
							.find('[data-tab]')
							.on('click', function () {
								const idx = $(this).parents('.js-line-item').index();

								_thisTab.find('.is-active').removeClass('is-active');
								$(this).addClass('is-active');

								// Add strix St 23.12.13
								$('.js-table-icon').removeClass('is-hide');
								// End strix St 23.12.13

								handleActiveLineTab(_thisTab, tabLine2, idx, $(this));

								for (let i = 0; i < 10; i++) {
									if ($(this).hasClass('is-bgx')) {
										tabLine2.removeClass('is-bgx');
									}
									tabLine2.removeClass(`is-bg${i}`);
								}

								for (let j = 0; j < 10; j++) {
									if ($(this).hasClass(`is-bg${j}`)) {
										tabLine2.addClass(`is-bg${j}`);
									}
								}
							});
					});
				}
			});
		}
	}

	handleCustomLineTab();

	$('.js-tab1-btn').on('click', function () {
		if ($(this).parents('.js-tab1').find('.js-tab2-btn').length) {
			handleCustomLineTab();
		}
	});

	wind.on('resize', function () {
		setTimeout(function () {
			handleCustomLineTab();
		}, 100);
	});
});

// タブ付きのスライダー（タブで切り替えることができるスライドショー）の設定
wind.on('load resize', function () {
	const tabSlide = $('.js-tab1-slide');
	let slider1;

	if (tabSlide.length) {
		if (wind.outerWidth() < 768) {
			tabSlide.each(function () {
				let idxActive = 0;
				const $this = $(this);
				if ($this.find('.nk23_c-tab2__navi__item.is-active').length) {
					idxActive = $this.find('.nk23_c-tab2__navi__item.is-active').index();
				}

				if ($this.find('.nk23_c-tab1__navi__item.is-active').length) {
					idxActive = $this.find('.nk23_c-tab1__navi__item.is-active').index();
				}

				slider1 = new Swiper($this.get(0), {
					initialSlide: idxActive,
					freeMode: true,
					slidesPerView: 'auto',
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
				});
			});
		} else {
			if (slider1) {
				slider1.destroy();
			}
		}
	}
});

// アコーディオンの設定
wind.on('load', () => {
	const tabAccor = $('.js-tab1-accor');
	const tabAccorContent = $('.js-tab1-accorcontent');
	const tabAccorBtnText = $('.js-tab1-accortext');

	if (tabAccor.length) {
		tabAccor.each(function () {
			const tabAccorBtn = $(this).find('.js-tab1-accorbtn');

			if (tabAccorBtn.length) {
				tabAccorBtn.on('click', function () {
					if ($(this).hasClass('is-active')) {
						$(this).parents('.js-tab1-accor').next(tabAccorContent).slideUp();
						$(this).removeClass('is-active').find(tabAccorBtnText).text('詳細');
					} else {
						$(this).parents('.js-tab1-accor').next(tabAccorContent).slideDown();
						$(this).addClass('is-active').find(tabAccorBtnText).text('閉');
					}
				});
			}
		});
	}
});

// あるサブタブがアクティブ状態にあるときに、そのサブタブをクリックすると切り替える機能
/*
$(function () {
	let _subtab = $('.nk23_c-tab5__navi__text.js-tab1-btn .nk23_c-tab5__subnavi');

	if (_subtab.find('.nk23_c-tab5__subnavi__item .nk23_c-tab5__subnavi__text').hasClass('is-active')) {

		_subtab.on('click', () => {
			$(_subtab).find('.nk23_c-tab5__subnavi__item .nk23_c-tab5__subnavi__text').toggleClass('is-active');

			if ($(_subtab).attr('data-tab-target') == 'date1') {
				$('.nk23_c-block01__toggle1').toggleClass('is-active');
			} else {
				$('.nk23_c-block01__toggle2').toggleClass('is-active');
			}
		});

	}
});
*/
$(function () {
	const subnaviText = $('.nk23_c-tab5__subnavi__text');
	subnaviText.on('click', function (ev) {
		ev.stopPropagation();
		ev.preventDefault();
		const subNavi = $(this).parents('.nk23_c-tab5__subnavi');
		$('.nk23_c-tab5__subnavi__text', subNavi).toggleClass('is-active');


		const tabTarget = $(this).parents('[data-tab-target]');
		if (tabTarget.length) {
			const target = $('[data-tab-id="' + tabTarget.data('tab-target') + '"]');
			$('.nk23_c-block01__toggle1', target).toggleClass('is-active');
			$('.nk23_c-block01__toggle2', target).toggleClass('is-active');

			// Add strix St 23.11.15
			// document.querySelector('body').classList.add('is-loading');

			setTimeout(function () {
				activeMatchHeight();
			}, 10);
			// Add strix End 23.11.15
		}
	});
});
