import $ from 'jquery';

import { bodyFix, bodyFixReset } from './_Functions.js';

$(function () {
	const modal1 = $('.js-modal1')
	const modal1WrapAll = $('.js-modal1-modal')

	if (modal1.length) {
		const afterTd = `
      <span class="is-after"></span>
    `

		modal1.each(function () {
			const btn = $(this).find('.js-modal1-btn')
			const wrap = $(this).find('.nk23_c-modal1__wrap')
			const scrollWrap = $(this).find('.nk23_c-modal1__scroll')
			const bottom = $(this).find('.nk23_c-modal1__bottom')

			btn.each(function () {
				$(this).on('click', function () {
					const wTr = $(this).parents('tr').innerWidth()
					const hTr = $(this).parents('tr').innerHeight()

					let bottomHeight = 0
					let padding = 40

					if ($(this).parents('.nk23_c-table01.is-style7').length) {
						$('.js-modal1-btn').removeClass('is-active')
						$(this).addClass('is-active')
					}

					bodyFix()

					$(this).parents('table').find('tr').removeClass('is-active')
					$(`[data-modal1-modal=${$(this).data('modal1')}]`).addClass('is-active').trigger('modal1-open');
					$(this).find('.nk23_c-btn01__text').length && $(this).find('.nk23_c-btn01__text').text('表示中')
					if ($(this).parents('table').find('.is-after').length) {
						$(this).parents('table').find('.is-after').remove()
					}

					$(this).parents('tr').find('.has-after').append(afterTd)

					$(this).parents('table').find('.is-after').css({
						width: wTr,
						height: hTr,
					})

					setTimeout(() => {
						if ($('.nk23_c-modal1--style1').length) {
							if ($(window).outerWidth() < 768) {
								$('.nk23_c-modal1--style1').each(function () {
									$(this).find('.nk23_c-table01__wrap').css('max-height', $(this).innerHeight() - 130)
								})
							}
						}
					}, 0);

					if (bottom.length) {
						bottomHeight = bottom.innerHeight()
					}

					// Update height of modal for page-guideline 05/12/2023
					if ($('.page-guideline').length) {
						if ($(window).outerWidth() < 768) {
							$('.nk23_c-modal1__scrollwrap').css('padding-bottom', '16px')

							if ($(window).outerWidth() < $(window).innerHeight()) {
								setTimeout(() => {
									$('.nk23_c-modal1__scroll').css('height', $('.nk23_c-modal1__wrap').innerHeight() - 114)

									if ($(window).outerWidth() < 375) {
										$('.nk23_c-modal1__scroll').css('height', $('.nk23_c-modal1__wrap').innerHeight() - 130)
									}
								}, 100);

							} else {
								setTimeout(() => {
									console.log(1);
									$('.nk23_c-modal1__scroll').css('height', $('.nk23_c-modal1__wrap').innerHeight() - 265)

									if ($(window).innerHeight() < 375) {
										console.log(2);
										$('.nk23_c-modal1__scroll').css('height', $('.nk23_c-modal1__wrap').innerHeight() - 319)
									}
								}, 100);
							}
						}
					}
					// end Update height of modal for page-guideline 05/12/2023

					setTimeout(() => {
						if ($(window).outerWidth() > 767) {
							$('.js-modal1-modal').innerHeight($(window).innerHeight())
							handleHeightModalWrap(scrollWrap, $(window).innerHeight(), bottomHeight, padding)

						} else {
							$('.js-modal1-modal').removeAttr('style')
						}
					}, 0);
				})
			})

			if (wrap.length) {
				let bottomHeight = 0
				let padding = 40

				if (bottom.length) {
					bottomHeight = bottom.innerHeight()
				}

				setTimeout(() => {
					handleHeightModalWrap(scrollWrap, $(window).innerHeight(), bottomHeight, padding)
				}, 1000);

				$(window).on('resize', function () {
					const hWindow = $(window).innerHeight()

					handleHeightModalWrap(scrollWrap, hWindow, bottomHeight, padding)

					if ($(window).outerWidth() < 768) {
						$('.js-modal1-modal').removeAttr('style')
					}
				})
			}
		})

		function handleHeightModalWrap(scrollWrap, heightOfWindow, bottomHeight, padding) {
			const contentScroll = scrollWrap.find('.nk23_c-modal1__scrollwrap')
			// const paddingContent = contentScroll.length && Number(contentScroll.css('paddingBottom').replace('px', ''))
			const hScroll = heightOfWindow - bottomHeight - padding
			let hContent = scrollWrap.find('.nk23_c-modal1__scrollwrap').innerHeight()

			// Update height of modal for page-guideline 05/12/2023
			if ($('.page-guideline').length) {
				hContent = $('.nk23_c-modal1__scrollwrap').innerHeight()
			}
			// end Update height of modal for page-guideline 05/12/2023

			if ($(window).outerWidth() > 767) {
				$('.js-modal1-modal').innerHeight($(window).innerHeight())
			}
			if ($(window).outerWidth() > 767) {
				if (hScroll > hContent) {
					scrollWrap.css('height', hContent)
					contentScroll.css('padding-bottom', 0)

					// Update height of modal for page-guideline 05/12/2023
					if ($('.page-guideline').length) {
						setTimeout(() => {
							$('.nk23_c-modal1__scrollwrap').css('padding-bottom', 0)
							$('.nk23_c-modal1__scroll').removeAttr('style')
						}, 100);
					}
					// end Update height of modal for page-guideline 05/12/2023

				} else {
					scrollWrap.css('height', heightOfWindow - bottomHeight - padding)
					contentScroll.removeAttr('style')

					// Update height of modal for page-guideline 05/12/2023
					if ($('.page-guideline').length) {
						setTimeout(() => {
							$('.nk23_c-modal1__scroll').css('height', hContent - 84)
						}, 100);
					}
					// end Update height of modal for page-guideline 05/12/2023
				}

				modal1WrapAll.each(function () {
					const hWrapScroll = $(this).find('.nk23_c-modal1__wrap').innerHeight()

					if (hWrapScroll >= ($(window).innerHeight() - 32)) {
						$(this).removeClass('is-flex')

					} else {
						$(this).addClass('is-flex')
					}
				})

			} else {
				scrollWrap.css('height', heightOfWindow - bottomHeight - padding)
			}
		}

		const btnClose = $('.js-close-modal1')

		if (btnClose.length) {
			btnClose.on('click', function () {
				closeModal()
			})
		}

		$('.js-modal1-modal').on('click', function (e) {
			if ($(e.target).is('.js-modal1-modal')) {
				closeModal()
			}
		})
	}

	function closeModal() {
		$('.js-modal1-modal').removeClass('is-active');
		$('.nk23_c-btn01.js-modal1-btn.is-active').removeClass('is-active');
		$('.js-modal1-btn .nk23_c-btn01__text').length && $('.js-modal1-btn .nk23_c-btn01__text').text('表示');
		bodyFixReset();
	}

	// Modal1 open scroll to table.
	// $('[data-modal1-modal]').on('modal1-open', function () {
	//   let scrollTo = $('.js-modal1-scrollto', this);
	//   if (!scrollTo.length) {
	//     scrollTo = $('.js-scroll-datatable', this);
	//   }
	//
	//   let scrollDiv = $('.nk23_c-modal1__scroll.js-scrollbar-scroll', this);
	//   if (scrollTo.length && scrollDiv.length) {
	//     scrollDiv.scrollTop((scrollDiv.scrollTop() + scrollTo.offset().top) - scrollDiv.offset().top);
	//   }
	// });

})
