import $ from 'jquery';

const wind = $(window);

$(function () {
	const jsWwitch = $('.js-switch1');

	if (jsWwitch.length) {
		jsWwitch.each(function () {
			const _thisSwitch = $(this);
			const btn = $(this).find('.js-switch1-btn');
			const radios = _thisSwitch.find('input[type=radio]');
			const glider = _thisSwitch.find('.js-glider');

			if (btn.length) {
				btn.each(function () {
					$(this).on('click', function () {
						const input = $(this).parents('form').find('.js-placeholder-input')

						let width = 0;

						$(this)
							.parents('.js-switch1')
							.find('.js-switch1-btn')
							.removeClass('is-active');
						$(this).addClass('is-active');

						if (radios.length) {
							radios.each(function () {
								$(this).attr('checked', false);
							});

							$(this).find('input[type=radio]').attr('checked', true);

							const idx = $(this).index();

							for (let i = 0; i < idx; i++) {
								width += _thisSwitch
									.find(`.js-switch1-btn:nth-child(${i + 1})`)
									.outerWidth();
							}

							if (idx === 0) {
								glider.css('left', width + idx + 4);
							} else {
								glider.css('left', width + idx + 2);
							}

							input.val('')

							if ($(this).find('input[type=radio]').data('radio') === 'レース名') {
								input.length && input.attr('placeholder', '全角文字 / 部分一致検索')

							} else {
								input.length && input.attr('placeholder', '全角カタカナ / 前方一致検索')
							}
						}
					});
				});
			}
		});
	}

	if ($('.page-top').length) {
		const fixedMenuBtn = $('.nk23_c-fixed__menu .nk23_c-tab6__navi__text');
		fixedMenuBtn.on('click', function () {
			fixedMenuBtn.removeClass('is-active');
			$(this).addClass('is-active');
		});
	}

	const jsCirle = $('.js-is-circle');
	let isCirleNum;
	let arrCheck = [];

	if (jsCirle.length) {
		jsCirle.each(function () {
			isCirleNum = $(this).parent().find('.is-circle').length;
			$(this).parent().find('td.nk23_u-text16.js-count').html(isCirleNum);
			showCheckCountColor();
			$(this).on('click', function () {
				// ドラッグ完了直後チェックをつけない
				if ($('.is-circle-disable').length > 0) return false;
				$(this).toggleClass('is-circle');
				isCirleNum = $(this).parent().find('.is-circle').length;
				$(this).parent().find('td.nk23_u-text16.js-count').html(isCirleNum);
				showCheckCountColor();
			});
		});
	}

	function showCheckCountColor() {
		var arrCheck = [];

		// 全頭のチェック数を取得
		$('.nk23_c-table22__table')
			.find('tr')
			.find('td.nk23_u-text16.js-count')
			.each(function () {
				arrCheck.push(parseInt($(this).html()));
			});
		arrCheck.pop();

		// 降順でソート
		arrCheck.sort(function (a, b) {
			return b - a;
		});

		// 色づけ対象のチェック件数を設定
		let taisyoCnt = 0;
		let checkCnt1st = 0;
		let checkCnt2nd = 0;
		let checkCnt3rd = 0;
		for (let i = 0; i < arrCheck.length; i++) {
			if (taisyoCnt >= 3) {
				break;
			}

			if (arrCheck[i] > 0) {
				if (checkCnt1st == 0) {
					taisyoCnt++;
					checkCnt1st = arrCheck[i];
				} else if (arrCheck[i] == checkCnt1st) {
					taisyoCnt++;
					continue;
				} else if (checkCnt2nd == 0 && taisyoCnt == 2) {
					taisyoCnt++;
					checkCnt3rd = arrCheck[i];
				} else if (checkCnt2nd == 0) {
					taisyoCnt++;
					checkCnt2nd = arrCheck[i];
				} else if (arrCheck[i] == checkCnt2nd) {
					taisyoCnt++;
					continue;
				} else if (checkCnt3rd == 0) {
					taisyoCnt++;
					checkCnt3rd = arrCheck[i];
				} else if (arrCheck[i] == checkCnt3rd) {
					taisyoCnt++;
					continue;
				}
			}
		}

		// 色設定
		$('.nk23_c-table22__table')
			.find('tr')
			.find('td.nk23_u-text16.js-count')
			.each(function () {
				const checkCnt = parseInt($(this).html());

				$(this).removeClass('nk23_u-bg-color35');
				$(this).removeClass('nk23_u-bg-color36');
				$(this).removeClass('nk23_u-bg-color37');

				if (checkCnt <= 0) {
					return true;
				}

				if (checkCnt == checkCnt1st) {
					$(this).addClass('nk23_u-bg-color35');
				} else if (checkCnt == checkCnt2nd) {
					$(this).addClass('nk23_u-bg-color36');
				} else if (checkCnt == checkCnt3rd) {
					$(this).addClass('nk23_u-bg-color37');
				}
			});
	}

	let _spBtn6Action = () => {
		let spBtn6 = $('.sp-btn6');
		if (spBtn6.length) {
			//spBtn6.each(function () {
			spBtn6.find('.onBtn').on('click', function () {
				// ドラッグ＆ドロップ時は処理をしない
				if ($(".is-circle-disable").length) return;

				if ($(this).hasClass('show')) {
					$(this).removeClass('show').next().removeClass('show');
				} else {
					$(this).addClass('show').next().addClass('show');
				}
			});
			spBtn6.find('.sp-list-btn6 > span').on('click', function () {
				// ドラッグ＆ドロップ時は処理をしない
				if ($(".is-circle-disable").length) return;

				const activeBtn = $(this).attr('class');
				const activeBtns = activeBtn.split(' ');
				switch (activeBtns[1]) {
					case 'is-active3':
						$(this)
							.parent()
							.removeClass('show')
							.parent()
							.parent()
							.find('.onBtn')
							.removeClass('show')
							.removeClass('is-active1')
							.removeClass('is-active2')
							.removeClass('is-active4')
							.removeClass('is-active5')
							.addClass('is-active3');
						if (
							$('.nk23_c-list10__list')
								.find('.icon_check-mark_3 .cb1 input')
								.prop('checked') == true
						) {
							$(this)
								.parent()
								.parent()
								.parent()
								.parent()
								.find('.cb1 input')
								.prop('checked', true);
						} else {
							$(this)
								.parent()
								.parent()
								.parent()
								.parent()
								.find('.cb1 input')
								.prop('checked', false);
						}
						if (
							$('.nk23_c-list10__list')
								.find('.icon_check-mark_3 .cb2 input')
								.prop('checked') == true
						) {
							$(this)
								.parent()
								.parent()
								.parent()
								.parent()
								.find('.cb2 input')
								.prop('checked', true);
						} else {
							$(this)
								.parent()
								.parent()
								.parent()
								.parent()
								.find('.cb2 input')
								.prop('checked', false);
						}
						if (
							$('.nk23_c-list10__list')
								.find('.icon_check-mark_3 .cb3 input')
								.prop('checked') == true
						) {
							$(this)
								.parent()
								.parent()
								.parent()
								.parent()
								.find('.cb3 input')
								.prop('checked', true);
						} else {
							$(this)
								.parent()
								.parent()
								.parent()
								.parent()
								.find('.cb3 input')
								.prop('checked', false);
						}
						break;
					case 'is-active2':
						$(this)
							.parent()
							.removeClass('show')
							.parent()
							.parent()
							.find('.onBtn')
							.removeClass('show')
							.removeClass('is-active1')
							.removeClass('is-active3')
							.removeClass('is-active4')
							.removeClass('is-active5')
							.addClass('is-active2');
						if (
							$('.nk23_c-list10__list')
								.find('.icon_check-mark_2 .cb1 input')
								.prop('checked') == true
						) {
							$(this)
								.parent()
								.parent()
								.parent()
								.parent()
								.find('.cb1 input')
								.prop('checked', true);
						} else {
							$(this)
								.parent()
								.parent()
								.parent()
								.parent()
								.find('.cb1 input')
								.prop('checked', false);
						}
						if (
							$('.nk23_c-list10__list')
								.find('.icon_check-mark_2 .cb2 input')
								.prop('checked') == true
						) {
							$(this)
								.parent()
								.parent()
								.parent()
								.parent()
								.find('.cb2 input')
								.prop('checked', true);
						} else {
							$(this)
								.parent()
								.parent()
								.parent()
								.parent()
								.find('.cb2 input')
								.prop('checked', false);
						}
						if (
							$('.nk23_c-list10__list')
								.find('.icon_check-mark_2 .cb3 input')
								.prop('checked') == true
						) {
							$(this)
								.parent()
								.parent()
								.parent()
								.parent()
								.find('.cb3 input')
								.prop('checked', true);
						} else {
							$(this)
								.parent()
								.parent()
								.parent()
								.parent()
								.find('.cb3 input')
								.prop('checked', false);
						}
						break;
					case 'is-active5':
						$(this)
							.parent()
							.removeClass('show')
							.parent()
							.parent()
							.find('.onBtn')
							.removeClass('show')
							.removeClass('is-active1')
							.removeClass('is-active2')
							.removeClass('is-active3')
							.removeClass('is-active4')
							.addClass('is-active5');
						if (
							$('.nk23_c-list10__list')
								.find('.icon_check-mark_5 .cb1 input')
								.prop('checked') == true
						) {
							$(this)
								.parent()
								.parent()
								.parent()
								.parent()
								.find('.cb1 input')
								.prop('checked', true);
						} else {
							$(this)
								.parent()
								.parent()
								.parent()
								.parent()
								.find('.cb1 input')
								.prop('checked', false);
						}
						if (
							$('.nk23_c-list10__list')
								.find('.icon_check-mark_5 .cb2 input')
								.prop('checked') == true
						) {
							$(this)
								.parent()
								.parent()
								.parent()
								.parent()
								.find('.cb2 input')
								.prop('checked', true);
						} else {
							$(this)
								.parent()
								.parent()
								.parent()
								.parent()
								.find('.cb2 input')
								.prop('checked', false);
						}
						if (
							$('.nk23_c-list10__list')
								.find('.icon_check-mark_5 .cb3 input')
								.prop('checked') == true
						) {
							$(this)
								.parent()
								.parent()
								.parent()
								.parent()
								.find('.cb3 input')
								.prop('checked', true);
						} else {
							$(this)
								.parent()
								.parent()
								.parent()
								.parent()
								.find('.cb3 input')
								.prop('checked', false);
						}
						break;
					case 'is-active1':
						$(this)
							.parent()
							.removeClass('show')
							.parent()
							.parent()
							.find('.onBtn')
							.removeClass('show')
							.removeClass('is-active2')
							.removeClass('is-active3')
							.removeClass('is-active4')
							.removeClass('is-active5')
							.addClass('is-active1');
						if (
							$('.nk23_c-list10__list')
								.find('.icon_check-mark_1 .cb1 input')
								.prop('checked') == true
						) {
							$(this)
								.parent()
								.parent()
								.parent()
								.parent()
								.find('.cb1 input')
								.prop('checked', true);
						} else {
							$(this)
								.parent()
								.parent()
								.parent()
								.parent()
								.find('.cb1 input')
								.prop('checked', false);
						}
						if (
							$('.nk23_c-list10__list')
								.find('.icon_check-mark_1 .cb2 input')
								.prop('checked') == true
						) {
							$(this)
								.parent()
								.parent()
								.parent()
								.parent()
								.find('.cb2 input')
								.prop('checked', true);
						} else {
							$(this)
								.parent()
								.parent()
								.parent()
								.parent()
								.find('.cb2 input')
								.prop('checked', false);
						}
						if (
							$('.nk23_c-list10__list')
								.find('.icon_check-mark_1 .cb3 input')
								.prop('checked') == true
						) {
							$(this)
								.parent()
								.parent()
								.parent()
								.parent()
								.find('.cb3 input')
								.prop('checked', true);
						} else {
							$(this)
								.parent()
								.parent()
								.parent()
								.parent()
								.find('.cb3 input')
								.prop('checked', false);
						}
						break;
					case 'is-active4':
						$(this)
							.parent()
							.removeClass('show')
							.parent()
							.parent()
							.find('.onBtn')
							.removeClass('show')
							.removeClass('is-active1')
							.removeClass('is-active2')
							.removeClass('is-active3')
							.removeClass('is-active5')
							.addClass('is-active4');
						if (
							$('.nk23_c-list10__list')
								.find('.icon_check-mark_4 .cb1 input')
								.prop('checked') == true
						) {
							$(this)
								.parent()
								.parent()
								.parent()
								.parent()
								.find('.cb1 input')
								.prop('checked', true);
						} else {
							$(this)
								.parent()
								.parent()
								.parent()
								.parent()
								.find('.cb1 input')
								.prop('checked', false);
						}
						if (
							$('.nk23_c-list10__list')
								.find('.icon_check-mark_4 .cb2 input')
								.prop('checked') == true
						) {
							$(this)
								.parent()
								.parent()
								.parent()
								.parent()
								.find('.cb2 input')
								.prop('checked', true);
						} else {
							$(this)
								.parent()
								.parent()
								.parent()
								.parent()
								.find('.cb2 input')
								.prop('checked', false);
						}
						if (
							$('.nk23_c-list10__list')
								.find('.icon_check-mark_4 .cb3 input')
								.prop('checked') == true
						) {
							$(this)
								.parent()
								.parent()
								.parent()
								.parent()
								.find('.cb3 input')
								.prop('checked', true);
						} else {
							$(this)
								.parent()
								.parent()
								.parent()
								.parent()
								.find('.cb3 input')
								.prop('checked', false);
						}
						break;
					default:
						$(this)
							.parent()
							.removeClass('show')
							.parent()
							.parent()
							.find('.onBtn')
							.removeClass('show')
							.removeClass('is-active1')
							.removeClass('is-active2')
							.removeClass('is-active3')
							.removeClass('is-active4')
							.removeClass('is-active5');
						$(this)
							.parent()
							.parent()
							.parent()
							.parent()
							.find('.cb1 input')
							.prop('checked', false);
						$(this)
							.parent()
							.parent()
							.parent()
							.parent()
							.find('.cb2 input')
							.prop('checked', false);
						$(this)
							.parent()
							.parent()
							.parent()
							.parent()
							.find('.cb3 input')
							.prop('checked', false);
						break;
				}
			});
			$('html').on('click', function () {
				spBtn6.addClass('show').next().addClass('show');
			});
			//})
		}
	};
	_spBtn6Action();

	// Add class HTML for page TOP
	$('.page-top').length &&
		$('.page-top').parents('html').addClass('is-page-top');

	//Toggle hide/show
	const toggleBtns = $('.js-toggle-show');

	toggleBtns.on('click', function () {
		const $this = $(this);
		if ($('.js-toggle-show-ele').length) {
			$('.js-toggle-show-ele').removeClass('nk23_u-hide');
		}

		if ($(this).hasClass('is-modal')) {

			const afterTd = `
        <span class="is-after"></span>
      `
			const wTr = $(this).parents('tr').innerWidth()
			const hTr = $(this).parents('tr').innerHeight()

			$('.js-toggle-show.is-modal').removeClass('is-active')
			$(this).addClass('is-active');


			$('[data-modal1-modal]').removeClass('is-show');
			const thisModal1 = $(`[data-modal1-modal=${$(this).data('modal1')}]`);
			if (thisModal1.length) {
				thisModal1.addClass('is-show');
				thisModal1.one('modal1-hide', function () {
					$this.parents('tr').find('.has-after > .is-after').remove();
					$this.removeClass('is-active');
					$('.nk23_c-btn01__text', $this).text('表示');
				});
			}

			$(this).parents('table').find('.nk23_c-btn01__text').text('表示')
			$(this).find('.nk23_c-btn01__text').length && $(this).find('.nk23_c-btn01__text').text('表示中')

			$(this).parents('table').find('tr').removeClass('is-active')
			if ($(this).parents('table').find('.is-after').length) {
				$(this).parents('table').find('.is-after').remove()
			}

			$(this).parents('tr').find('.has-after').append(afterTd)

			$(this).parents('table').find('.is-after').css({
				width: wTr,
				height: hTr,
			})
		}
	});

	$('.js-close-modal1').on('click', function () {
		let modalShow = $(this).parents('.is-show[data-modal1-modal]');
		if (modalShow.length) {
			modalShow.removeClass('is-show');
			modalShow.trigger('modal1-hide');
		}
	});

	$('.js-new-window').each(function () {
		$(this).on('click', function (event) {
			event.preventDefault();
			let link = $(this).attr('href');

			if (navigator.userAgent.match(/iPhone|iPad|Android.+Mobile/)) {
				let newWindow = window.open(link, '_blank');
				newWindow.opener = null;
				newWindow.location.rel = 'noopener noreferrer';
			} else {
				window.open(link, '_blank', 'top=100,left=100');
			}
		});
	});

	// register
	$('.js-register').on('click', function () {
		alert('登録しました');
	});

	// 更新ボタン
	$('a.nk23_c-btn04.is-top').on('click', function () {
		window.location.reload();
	});
	_UA();
});
function _UA() {
	//userAgent
	let userAgentData = window.navigator.userAgentData;
	if (userAgentData) {
		// ブラウザがuserAgentData に対応している場合
		let mobile = userAgentData.mobile;
		if (mobile) {
			$('html').addClass('smartphone');
			$('.nk23_c-header').addClass('smartphone');
		} else {
			$('html').removeClass('smartphone');
			$('.nk23_c-header').removeClass('smartphone');
		}
	} else {
		// ブラウザがuserAgentData に対応していない場合
		let userAgent = window.navigator.userAgent.toLowerCase();
		if (userAgent.match(/(iPhone|iPad|iPod|Android)/i)) {
			$('html').addClass('smartphone');
			$('.nk23_c-header').addClass('smartphone');
		} else {
			$('html').removeClass('smartphone');
			$('.nk23_c-header').removeClass('smartphone');
		}
	};

	// タブレットの場合
	if ((/iPad/.test(navigator.userAgent)) ||
		((/Android/.test(navigator.userAgent) && (/Mobile/.test(navigator.userAgent) === false)))) {
		$('html').addClass('tablet');
	} else {
		$('html').removeClass('tablet');
	}
}
wind.on('resize', function () {
	_UA();
});
