import $ from 'jquery';

$(window).on('load', function () {
	const body = $('body');
	const wrapperEle = $('#wrapper');
	const wind = $(window);
	const footer = $('.js-footer');
	const btnTotop = $('.js-totop');

	function checkFixedFooter() {
		setTimeout(function () {
			if (footer.length) {
				if (wrapperEle.innerHeight() < wind.innerHeight()) {
					footer.addClass('is-fixed');
				} else {
					footer.removeClass('is-fixed');
				}
				if ($('.js-footer.is-fixed').length) {
					const hFooter = $('.js-footer.is-fixed').innerHeight();
					if (wrapperEle.innerHeight() + hFooter >= wind.innerHeight()) {
						footer.removeClass('is-fixed');
					} else {
						footer.addClass('is-fixed');
					}
				}
			}
		}, 50);
	}

	checkFixedFooter();

	function handleUpdateFooter() {
		setInterval(function () { checkFixedFooter() }, 0);
	}

	$('.js-hide').on('click', function () { handleUpdateFooter }());

	$(window).on('click', function () { handleUpdateFooter }());

	$(window).on('resize orientationchange', function () { checkFixedFooter() });

	const fixedMenu = $('.nk23_c-fixed__menu');
	$(function () {
		let height = fixedMenu.innerHeight();
		$('.page-top .nk23_c-footer').css({
			'padding-bottom': height,
			// 'background-color': '#221f1f',
		});
	});

	if (btnTotop.length) {
		wind.on('scroll', function () {
			if ($(window).scrollTop() > 50) {
				btnTotop.addClass('is-show');
			} else {
				btnTotop.removeClass('is-show');
			}
		});
		function handleToTopSp() {
			if ($('html').outerWidth() < 768) {
				if ($('html').outerWidth() > $(window).innerHeight()) {
					btnTotop.css(
						{
							'right': '16px'
						}
					);
				}
			}
		}

		let _spaceW;
		function checkWidth() {
			let _windW = wind.outerWidth();
			let _bodyW = $('body').outerWidth();
			_spaceW = _windW - _bodyW;
			//console.log(_spaceW);
			if (_spaceW > 0) {
				btnTotop.css({ right: 40 + _spaceW });
			}
		}
		function checkScroll() {
			let winh = window.innerHeight + window.pageYOffset;
			let bodyh = $('#wrapper').height();
			let fh = footer.outerHeight();
			let scroll = wind.scrollTop();
			let footerPos = footer.offset().top;
			const breakpoint = 768;

			if (bodyh - winh <= fh) {
				if (_spaceW > 0) {
					btnTotop.removeAttr('style');
				}
				btnTotop.addClass('is-absolute');
				if (
					btnTotop.hasClass('is-absolute') &&
					window.innerWidth > breakpoint
				) {
					let pos = scroll + window.innerHeight - footerPos + 16;
					btnTotop.css('bottom', pos);
				}
			} else {
				btnTotop.removeClass('is-absolute');
				if (window.innerWidth > breakpoint) {
					btnTotop.css('bottom', '16px');
				}
			}
		}

		checkWidth();
		handleToTopSp();

		let timeOutResize;
		$(window).on('orientationchange resize', function () {
			clearTimeout(timeOutResize);
		 	timeOutResize = setTimeout(function () {
		 		//場タブをアニメーション化するタグの位置が再調整されてから、トップボタンの位置を調整する
				checkWidth()
				handleToTopSp()
		 	}, 700);
		});
		$(window).on('scroll resize', function () {
			checkScroll()
			handleToTopSp()
		});
	}

	let scroll;

	$(window).on('scroll', function () {
		const scrollTop = $(this).scrollTop();
		const isScrollUp = scrollTop < scroll;

		// 表示スクロールポイント取得
		var off = $('.js-fixedMenu').show().offset();

		if (off !== undefined) {
			if (scrollTop < off.top + 65) {
				fixedMenu.removeClass('is-show');
			} else {
				fixedMenu.addClass('is-show');
			}

			scroll = scrollTop;
		}
	});

	// MutationObserver の設定
	var footerObserver = new MutationObserver(function (mutations) {
		mutations.forEach(function (mutation) {
				if (mutation.target.classList.contains('nk23_c-table01') || mutation.target === document.body) {
						checkFixedFooter();
				}
		});
	});
	// 監視する対象の要素と監視対象の変更を指定
	var footerObserverConfig = { subtree: true, childList: true };
	// 監視を開始する
	footerObserver.observe(document.body, footerObserverConfig);

	btnTotop.on('click', function () {
		$('html, body').animate(
			{
				scrollTop: 0,
			},
			300,
		);
	});

	$('.link-pagetop').on('click', function () {
		$('html, body').animate(
			{
				scrollTop: 0,
			},
			300,
		);
	});
});
