import $ from 'jquery';

$('a[href*=\\#]:not([href=\\#])').on('click', function () {
	if (
		location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
		location.hostname == this.hostname
	) {
		let $target = $(this.hash);
		$target =
			($target.length && $target) || $('[name=' + this.hash.slice(1) + ']');
		const offsetTop = $('.nk23_c-header').height();
		if ($target.length) {
			const targetOffset = $target.offset().top - offsetTop;
			$('html,body').animate({ scrollTop: targetOffset }, 1000);
			return false;
		}
	}
});

$(function () {
	let defaultOptionValue = ""
	$(".js-select01").on('change', function () {
		let selectedOptionValue = $(this).val();
		let sectionToScrollTo = $("#scrollToSection" + selectedOptionValue);

		if (sectionToScrollTo.length > 0) {
			let scrollTo = sectionToScrollTo.offset().top
			if ($(window).outerWidth() < 768) {
				if ($('.js-scrolled-fixed').length) {
					scrollTo -= $('.js-scrolled-fixed').innerHeight()
				}
			}

			$('html, body').animate({
				scrollTop: scrollTo
			}, 1000);
		}
		if (selectedOptionValue === defaultOptionValue) {
			// Reset select element
			$(this).prop('selectedIndex', -1)
		}
		//  $(this).prop('selectedIndex', 0);
	});
	$(".js-select01").on('click', function () {
		defaultOptionValue = $(this).val()
	});

	// openWindowLink
	$(".js-openWindowLink").on('click', function () {
		let url = $(this).data("url");
		let windowFeatures = "width=656,height=645,scrollbars=no,resizable=no, menubar=1 toolbar=1, scrollbars=no, directories=no, status=1, location=1, statusbar=1";
		window.open(url, "_blank", windowFeatures);
	});
});
