import $ from 'jquery';

const wind = $(window);
const wrapperEle = $('#wrapper');
const footer = $('.js-footer');
const bodyHTML = $('html, body')

let scrollPosi

function handleFooterFixedAccor(ele) {
	// if ($(ele).length) {
	//   const hAccor = $('#wrapper').innerHeight()
	//   const hWindow = $(window).innerHeight()

	//   if (hWindow > hAccor) {
	//     $('.js-footer').addClass('is-fixed');
	//   } else {
	//     $('.js-footer').removeClass('is-fixed');
	//   }
	// }
}

function checkFixedFooter() {
	setTimeout(() => {
		// if (footer.length) {
		//   if (wrapperEle.innerHeight() < wind.innerHeight()) {
		//     footer.addClass('is-fixed');
		//   }

		//   if ($('.js-footer.is-fixed').length) {
		//     const hFooter = $('.js-footer.is-fixed').innerHeight();

		//     if (wrapperEle.innerHeight() + hFooter >= wind.innerHeight()) {
		//       footer.removeClass('is-fixed');
		//     }
		//   }
		// }

		// handleFooterFixedAccor('.js-hide-content')
	}, 0);
}

function changeWidthHover(ele, dis = 2) {
	$(ele).each(function () {
		$(this).removeAttr('style')

		if (!$(this).hasClass('is-active')) {
			if (wind.outerWidth() > 767) {
				const w = $(this).innerWidth()

				$(this).innerWidth(w + dis)
			} else {
				$(this).removeAttr('style')
			}
		}
	})
}

// Fixed element when scroll
function handleFixedScrolled() {
	const scrolleEle = $('.js-scrolled-fixed')

	if (scrolleEle.length) {
		scrolleEle.each(function () {
			const _thisEle = $(this)
			const _thisParent = $(this).parent()
			const marginBottomParent = Number($(this).parent().css('marginBottom').replace('px', ''))
			const marginBottom = marginBottomParent > 0 ? marginBottomParent : marginBottomParent + 4
			const spacing = _thisEle.outerHeight() + marginBottom

			_thisEle.removeClass('is-fixed')
			_thisParent.removeAttr('style')

			$(window).on('scroll', () => {
				if ($(window).scrollTop() >= _thisParent.offset().top) {
					_thisEle.addClass('is-fixed')
					_thisParent.css('margin-bottom', spacing)

				} else {
					_thisEle.removeClass('is-fixed')
					_thisParent.removeAttr('style')
				}
			})
		})
	}
}

function detectIos() {
	return [
		'iPad Simulator',
		'iPhone Simulator',
		'iPod Simulator',
		'iPad',
		'iPhone',
		'iPod'
	].includes(navigator.platform)
		|| (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

function detectAndroid() {
	const ua = navigator.userAgent.toLowerCase();
	const isAndroid = ua.indexOf("android") > -1;
	return isAndroid
}

function bodyFix() {
	const wrapperEle = $('#wrapper');

	scrollPosi = $(window).scrollTop();
	wrapperEle.addClass('is-fixed').css({ top: -scrollPosi });
}

function bodyFixReset() {
	const wrapperEle = $('#wrapper');

	wrapperEle.removeClass('is-fixed').css({ top: '0' });
	bodyHTML.scrollTop(scrollPosi);
}

export { checkFixedFooter, handleFixedScrolled, detectIos, detectAndroid, bodyFix, bodyFixReset }
export default changeWidthHover


wind.on('load pageshow', function() {
	// ハンバーガーメニューを開いたまま画面遷移したり、ブラウザバックした際にスクロール不可になる対策（強制的に閉じる）
	$('#wrapper').removeClass('is-fixed');
	$('.nk23_c-header__content').removeClass('is-open');
});
wind.on('resize', function() {
	// ハンバーガーメニューを開いたまま縦横を切り替えたりした際にスクロール不可になる対策（強制的に閉じる）
	// リサイズでPC表示になった場合だけ閉じる（スマホの場合はそのまま）
	if (window.matchMedia( "(orientation: landscape)" ).matches ||
    (window.matchMedia( "(orientation: portrait)" ).matches && window.matchMedia('(min-width: 768px)').matches)) {
      $('#wrapper').removeClass('is-fixed');
      $('.nk23_c-header__content').removeClass('is-open');
    }
});