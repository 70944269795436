import $ from 'jquery';
import 'jquery-match-height';

const wind = $(window);

function mHeight(parent, items, itemeles, eles, breakpoint = 'pc') {
	const mheight = document.querySelectorAll(parent);
	let heightArrTotal = [];
	let heightArr = [];
	let heightArrEle = [];
	let itemHeight;
	let eleHeight;
	let arrHeight = [];

	if (mheight.length) {

		if ($(eles).length) {
			$(eles).removeAttr('style');
		}

		mheight.forEach((item) => {
			itemHeight = item.querySelectorAll(items);

			itemHeight.forEach((ele) => {
				eleHeight = ele.querySelectorAll(eles);

				eleHeight.forEach((itemele) => {
					heightArrEle.push(itemele.clientHeight);
				});

				heightArr.push(heightArrEle);
				heightArrEle = [];
			});

			heightArrTotal.push(heightArr);
			heightArr = [];
		});
		heightArrTotal.map((hitem) => {
			let max = 0;
			// 対象の要素が取得できなかった場合heightArrTotalのlengthが0になり、reduceでエラーが発生する対策
			if (hitem.length) {
				max = hitem.reduce(function (final, current) {
					for (let i = 0; i < final.length; ++i) {
						if (current[i] > final[i]) final[i] = current[i];
					}
					return final;
				});
			}

			arrHeight.push(max);
		});

		$(parent).each(function (idx) {
			const itemEle = $(this).find(items);

			itemEle.each(function () {
				arrHeight[idx].map((h, i) => {
					Promise.resolve().then(() => {
						// Add strix St 23.11.16
						// [note] 高さ取得できない場合「auto」が入るように調整
						if(h == 0) {
							$(this)
								.find(`${itemeles}:nth-child(${i + 1}) ${eles}`).css({
									height: 'auto'
								});
						} else {
							$(this)
								.find(`${itemeles}:nth-child(${i + 1}) ${eles}`)
								.outerHeight(h)//20230427 変更
						}
						// Add strix End 23.11.16
					}).then(() => {
						$(parent).css({
							opacity: 1
						});
					});

					if (breakpoint !== 'sp') {
						if (wind.outerWidth() < 768) {
							$(eles).removeAttr('style');
						}
					}

				});

			});
		});

		// Add strix St 23.11.15
		// document.querySelector('body').classList.remove('is-loading');
		// Add strix End 23.11.15
	} else {
		$(parent).css({
			opacity: 1
		});
		// Add strix St 23.11.15
		// document.querySelector('body').classList.remove('is-loading');
		// Add strix End 23.11.15
	}
}

$(window).on('load resize', () => {
	setTimeout(function(){

		activeMatchHeight();
		$('.nk23_c-block02__ttl2').matchHeight();
		$('.js-match-height').matchHeight();
		$('.nk23_c-block01__info').matchHeight();

		// Add strix St
		$('.nk23_c-banner1__body').matchHeight();
		// Add strix End

		$('.is-MatchHeight').matchHeight();
	}, 10);
});

export default function activeMatchHeight() {
	mHeight(
		'.js-mheight',
		'.js-mheight-item',
		'.js-mheight-itemele',
		'.nk23_c-list01__info',
		'sp',
	);
	mHeight(
		'.js-mheight',
		'.js-mheight-item',
		'.js-mheight-itemele',
		'.js-mheight-ele',
	);
	mHeight(
		'.js-mheight',
		'.js-mheight-item',
		'.js-mheight-itemele',
		'.nk23_c-list01__btn',
		'sp',
	);
	mHeight(
		'.js-mheight',
		'.js-mheight-item',
		'.js-mheight-itemele',
		'.js-mheight-btns',
		'sp',
	);
	mHeight(
		'.js-mheight',
		'.js-mheight-item',
		'.js-mheight-itemele',
		'.nk23_c-list01__heading',
		'sp',
	);
	mHeight(
		'.js-mheight',
		'.js-mheight-item',
		'.nk23_c-block01__top',
		'.nk23_c-block01__on',
		'sp',
	);
	mHeight(
		'.js-mheight',
		'.js-mheight-item',
		'.nk23_c-block01__top',
		'.nk23_c-block01__on2',
		'sp',
	);

	mHeight(
		'.js-mheight',
		'.js-mheight-item',
		'.js-mheight-itemele',
		'.nk23_c-list01__info2',
		'sp',
	);
	mHeight(
		'.js-mheight',
		'.js-mheight-item',
		'.js-mheight-itemele',
		'.js-mheight-btns2',
		'sp',
	);
	mHeight(
		'.js-mheight',
		'.js-mheight-item',
		'.js-mheight-itemele',
		'.nk23_c-list01__heading2',
		'sp',
	);
}
