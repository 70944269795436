import $ from 'jquery';


window.addEventListener("load", function () {
	// data-js="line-tab"
	const lineTabCallback = function (elem) {
		const $elem = $(elem);
		const items = $('[data-line-item]', elem);
		const itemActive = $('.is-active[data-line-item]', elem);
		const bar = $('[data-line-bar]', elem);
		if (items.length && bar.length) {
			let barTimeout;
			const barClass = bar.attr('class');
			const activeLine = function (item, type) {
				let $item = $(item);
				let parentOffsetLeft = $elem.offset().left;
				let offsetLeft = $item.offset().left;
				let addClass = $item.data('line-class');
				clearTimeout(barTimeout);

				items.not(item).removeClass('is-active').addClass('is-no-bg');
				$item.addClass('is-active').removeClass('is-no-bg');
				bar.attr('class', barClass);
				if (typeof addClass === 'string') {
					bar.addClass(addClass);
					$item.addClass(addClass);
				}

				bar.css('transform', 'translate3d(' + (offsetLeft - parentOffsetLeft) + 'px,0,0)');
				bar.css('width', $item.innerWidth());

				if (type !== 'load') {
					bar.addClass('is-active');
					barTimeout = setTimeout(function () {
						bar.removeClass('is-active');
					}, 900);
				}
			}

			items.on('click', function () {
				activeLine(this);
			});

			items.on('line-tab-click', function () {
				activeLine(this);
			});

			if (itemActive.length) {
				activeLine(itemActive, 'load');
			}

			let timeOutResize;
			$(window).on('resize-x', function () {
				clearTimeout(timeOutResize);
			 	timeOutResize = setTimeout(function () {
			 		//場タブの切替時にアニメーション化するタグの位置を再調整する
			 		const resizeItemActive = $('.is-active[data-line-item]', elem);
			 		if (resizeItemActive.length) {
			 			activeLine(resizeItemActive);
			 		}
			 	}, 300);
			});

			//Check data-tab-id is-active
			const tabElemId = $elem.parents('[data-tab-id]');
			if (tabElemId.length) {
				tabElemId.on('after-tab-active', function () {
					const tabLineActive = $('.is-active[data-line-item]', elem);
					if (!tabLineActive.length) {
						bar.removeClass('is-active');
					}
				});
			}
		}
	}
	const lineTabElems = $('[data-js="line-tab"]');
	for (let i = 0; i < lineTabElems.length; i++) {
		lineTabCallback(lineTabElems[i]);
	}

	// data-js="tab..."
	const tabCallback = function (name, classActive = 'is-active') {
		//日付、競馬場、ニュースイベントタブ
		const tabTargets = $('[data-js="' + name + '"][data-tab-target]');
		//日付タブの場合、表示する開催エリアを補正するイベントを登録する
		if(name == "tab1"){
			//表示する開催エリアを補正する
			tabTargets.on('click-call-back', function() {
				//日付タブの属性を取得
				const target = $(this).data('tab-target');
				//日付タブの属性により対象の日付の表示競馬場を取得する
				const jo = $('#firstSelectJo' + target).val();
				//日付タブに対し、全競馬場の開催情報とレース状況WRAPを取得する
				const tabId = $('[data-js="' + name + '"][data-tab-id="' + target + '"]');
				//日付タブの全競馬場の開催情報とレース状況WRAPに対し、競馬場タブを取得する
				const joTabs = tabId.find('.nk23_c-tab6__navi__text');
				//日付タブの全競馬場の開催情報とレース状況WRAPに対し、対象日付の競馬場の開催情報とレース状況を取得する
				const joContents = tabId.find('.nk23_c-tab6__content');
				//全場非開催時
				if (jo == "noRace") {
					//場タブを非活性にする
					joTabs.removeClass(classActive);
					//全場非開催だけ表示する
					$.each(joContents,(index,joContent) => {
						const tabIdVal = $(joContent).attr("data-tab-id");
						if (tabIdVal == "all") {
								$(joContent).addClass(classActive);
						} else {
								$(joContent).removeClass(classActive);
						}
					});
				//対象の日付が全場非開催以外
				} else {
					//表示されている競馬場がない
					//以下のケースを想定
					//前の選択場が協同開催　且つ　対象の日付に協同開催なし　
					if (!joContents.hasClass(classActive)) {
						//対象の場タブを取得する
						const joTab = tabId.find('.nk23_c-tab6__navi__text[data-js="tab2"][data-tab-target="' + jo + '"]');
						//対象の競馬場の開催情報とレース状況を取得する
						const joContent = tabId.find('.nk23_c-tab6__content[data-tab-id="' + jo + '"]');
						//活性クラスを付与
						$(joTab).addClass(classActive);
						//対象のタブ活性アクションを実行する
						joTab.trigger('line-tab-click');
						//対象の開催情報とレース状況を表示する
          	$(joContent).addClass(classActive);
					}
				}
			})
		}

		//場タブの場合、表示する開催エリアを補正するイベントを登録する
		if(name == "tab2"){
			//表示する開催エリアを補正する
			//対象の場タブの開催状態により一覧・直近タブを活性・非活性制御する
			tabTargets.on('click-call-back', function() {
				//対象の場タブが本日タブの場タブか判定
				const tabId = $(this).parents('.jo-tab').attr("data-tab-id");
				//本日タブの場タブの場合
				if(tabId == "2"){
					const kaisai = $(this).attr("data-kaisai");
					const target = $('#todayNavi');
					//開催の場合
					if(kaisai == "true"){
						target.removeClass('is-disable')
					//非開催の場合
					} else {
						target.addClass('is-disable')
					}
				}
			})
		}

		tabTargets.on('click', function () {
			//タブクリックによって表示する複数のエリア
			const tabIds = $('[data-js="' + name + '"][data-tab-id]');
			//切り替えタブの"data-tab-target"値
			const target = $(this).data('tab-target');
			//日付タブの場合、日付タブの競馬場タブを含む開催情報エリア
			//競馬場タブの場合、開催情報エリア
			//ニュースイベントタブの場合、ニュースイベントコンテンツ
			const tabTargetId = $('[data-js="' + name + '"][data-tab-id="' + target + '"]');
			//クリックしたタブ自身の"data-tab-target"値
			const tabTargetThis = $('[data-js="' + name + '"][data-tab-target="' + target + '"]');
			if (tabTargetId.length || tabTargetThis.length) {
				//未使用
				tabTargetId.trigger('before-tab-active');
				//表示する対象以外は非表示にする
				tabIds.not(tabTargetId).removeClass(classActive);
				//表示する対象を表示にする
				tabTargetId.addClass(classActive);
				//クリックしたタブ以外は非活性にする
				tabTargets.not(tabTargetThis).removeClass(classActive);
				//クリックしたタブは活性にする
				tabTargetThis.addClass(classActive);
				tabTargetThis.not(this).trigger('line-tab-click');

				tabTargetId.trigger('after-tab-active');
				//表示する開催エリアを補正する
				tabTargetThis.trigger('click-call-back');
			}
			//タブのgtag関連処理
			invokeGtag(this);
		});
	}

	/**
	* クリック統計のためにgtagを呼び出す
	* @param tab {dom} クリックしたタブのy要素
	*/
	const invokeGtag = function(tab){
		//console.log("タブクリック : " + tab)
		//アクション
		const gtagAction = $(tab).data('gtag-action');
		//ラベル
		const gtagLabel = $(tab).data('gtag-label');

		//gtagメソッドが存在する時のみ呼び出す
		//※ローカル環境またはモックではgtagメソッドが存在しなくてエラーになるためチェック処理を挟む
		if (typeof gtag === 'function') {
			//console.log(`'event', ${gtagAction}, {'event_category': 'click', 'event_label': ${gtagLabel}}`)
			//gtagを呼び出す
			gtag('event', gtagAction, {'event_category': 'click', 'event_label': gtagLabel});
			//console.log("gtag 呼び出し完了")
		}
	}

	tabCallback('tab1'); //日付タブ
	tabCallback('tab2'); //競馬場タブ
	tabCallback('tab3'); //未使用
	tabCallback('tab-news'); //ニュースイベントタブ
});
