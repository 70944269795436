import $ from 'jquery';

$(window).on('load', () => {
  const zoomEles = $('.js-zoom')

  function updateZoom(content, value) {
    content.css('transform', `scale(${value})`)
    // content.parents('.js-zoom').find('.scroll-content').innerHeight(content[0].getBoundingClientRect().height)
    content.parents('.js-zoom').find('.custom-scrollbar-inner').innerHeight(content[0].getBoundingClientRect().height)
  }

  // function handleBtnZoom(btns, content) {
  //   const halfOfWindow = $(window).innerHeight() / 2

  //   if (content.offset().top > 0) {
  //     if (($(window).scrollTop() + $(window).innerHeight() - content.offset().top - content[0].getBoundingClientRect().height - 10) >= 0) {
  //       btns.removeClass('is-show')

  //     } else {
  //       if (($(window).scrollTop() + $(window).innerHeight() - content.offset().top - halfOfWindow) >= 0) {
  //         btns.addClass('is-show')

  //       } else {
  //         btns.removeClass('is-show')
  //       }
  //     }
  //   }
  // }

  // Add strix St
  function handleBtnZoom(btns, content) {
    // IntersectionObserverがサポートされていないブラウザでは実行しない
    if (
      !('IntersectionObserver' in window) ||
      !('IntersectionObserverEntry' in window) ||
      !('isIntersecting' in window.IntersectionObserverEntry.prototype)
      ) {
      return;
    }

    const $zoomContent = document.querySelectorAll('.js-zoom-content');
		const view_zoomContentArray = Array.prototype.slice.call($zoomContent, 0);
    const $isShow = 'is-show';

    const option_zoomBtn = {
      root: null,
      rootMargin: '0px 0px -66%',
      threshold: 0
    };
    const obsZoomBtn = new IntersectionObserver(scrFixed, option_zoomBtn);

		view_zoomContentArray.forEach(function (view) {
			obsZoomBtn.observe(view);
		});

    function scrFixed(e) {

			const entriesArray = Array.prototype.slice.call(e, 0);
			entriesArray.forEach(function (entry) {
				let parentZoom = entry.target.closest('.js-zoom');
				let currentZoomBtn = parentZoom.querySelector('.js-zoom-btns');
				if (entry.isIntersecting) {
					currentZoomBtn.classList.add($isShow);
				} else {
					currentZoomBtn.classList.remove($isShow);
				}
			});

			// if (e[0].isIntersecting) {
			//   btns.addClass($isShow);
			// } else {
			//   btns.removeClass($isShow);
			// }
    }
  }
  // Add strix End

  function handleWidthScrollBarCustom(ele) {
    const parent = $(ele.target).parents('.js-zoom')
    const inner = parent.find('.custom-scrollbar-inner')
    const table = parent.find('table')
    const scrollbarX = parent.find('.custom-scrollbar-x')

    if (inner.length) {
      const per = inner.innerWidth() / table[0].getBoundingClientRect().width * 100

      scrollbarX.css('width', per + '%')

      function updateScrollBar() {
        const transx = inner.scrollLeft() / table[0].getBoundingClientRect().width * 100

        scrollbarX.css({
          left: transx + '%'
        })
      }

      updateScrollBar()

      inner.on('scroll', function () {
        updateScrollBar()
      })
    }
  }

  function handleZoomFixed(e, initScale) {
    const tableZoom = $(e.target).parents('.js-zoom').find('.js-scroll-fixed-col table')

    $(e.target).parents('.js-zoom').find('.js-scroll-fixed-top').css({
      'transform': `scale(${initScale})`
    })

    tableZoom.css({
      'transform': `scale(${initScale})`,
      'transition': 'none'
    })

    $(e.target).parents('.js-zoom').find('.js-scroll-fixed-col').css({
      'width': tableZoom.find('tr td:first-child')[0].getBoundingClientRect().width + tableZoom.find('tr td:nth-child(2)')[0].getBoundingClientRect().width,
      'height': tableZoom[0].getBoundingClientRect().height
    })
  }

  if (zoomEles.length) {
    zoomEles.each(function () {
      const btnZoomIn = $(this).find('.js-zoom-in')
      const btnZoomOut = $(this).find('.js-zoom-out')
      const content = $(this).find('.js-zoom-content')
      const btns = $(this).find('.js-zoom-btns')

      let initScale = 1

      handleBtnZoom(btns, content)

      $(window).on('scroll', () => {
        handleBtnZoom(btns, content)
      })

      btnZoomIn.on('click', (e) => {
        if (initScale < 2) {
          initScale += 0.1;
        }

        $('.js-scroll-fixed-titletop').css({
          'transform': `scale(${initScale})`
        })
        updateZoom(content, initScale)
        handleBtnZoom(btns, content)
        handleWidthScrollBarCustom(e)
        handleZoomFixed(e, initScale)
      })

      btnZoomOut.on('click', (e) => {
        if (initScale > 0.4) {
          initScale -= 0.1;
        }

        $('.js-scroll-fixed-titletop').css({
          'transform': `scale(${initScale})`
        })
        updateZoom(content, initScale)
        handleBtnZoom(btns, content)
        handleWidthScrollBarCustom(e)
        handleZoomFixed(e, initScale)
      })

      $(window).on('resize', () => {
        if ($(window).outerWidth() > 767) {
          initScale = 0.4
        }
      })
    })
  }
})
