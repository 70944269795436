import $ from 'jquery';

import { bodyFix, bodyFixReset } from './_Functions.js';

const wind = $(window);
$(function () {
	$('.accordion__ttl').on('click', function () {
		if (wind.outerWidth() < 768) {
			if ($(this).hasClass('active')) {
				$(this).next('.accordion__table').stop().slideDown();
				$(this).removeClass('active');
			} else {
				$(this).next('.accordion__table').stop().slideUp();
				$(this).addClass('active');
			}
			return false;
		}
	});
});

$(function () {
	$('.js-accordion2').on('click', '.js-accordion-heading2', function (e) {
		// e.preventDefault();
		$(this).toggleClass('is-open');
		if (!$(this).hasClass('nk23_c-btn01')) {
			$('.nk23_c-block13').toggleClass('is-open');
		}
		$(this).next('.js-accordion-content2').slideToggle(300, 'swing');
	});

	$('.js-accordion').each(function () {
		$('.btn-open', this).on('click', function () {
			$(this).addClass('is-open');
			$('.js-accordion-content').slideDown();
		});
		$('.btn-close', this).on('click', function () {
			$('.btn-open').removeClass('is-open');
			$('.js-accordion-content').slideUp();
		});
	});

	if ($('.js-accordion2').length) {

		$('.js-accordion2').each(function () {
			const heading = $(this).find('.js-accordion-fixed');
			const content = $(this).find('.js-accordion-content2');

			if (heading.length) {
				heading.on('click', () => {
					$(window).scrollTop(heading.offset().top);

					// Add strix St
					// コンテンツの高さが短いときでも固定にする
					let objEle = $('.js-scrolled-fixed');
					let objEleParent = objEle.parent();
					let objEleParentMarginBtm = Number(objEleParent.css('marginBottom').replace('px', ''))
					let objElemarginBtm = objEleParentMarginBtm > 0 ? objEleParentMarginBtm : objEleParentMarginBtm + 4
					let objEleSpacing = objEle.outerHeight() + objElemarginBtm;

					setTimeout(() => {
						if (objEle.hasClass('is-fixed-btm')) {
							objEle.removeClass('is-fixed-btm');
							objEleParent.removeAttr('style');
						} else {
							objEle.addClass('is-fixed-btm');
							objEleParent.css('margin-bottom', objEleSpacing);
						}
					}, 0);
					// Add strix End

					setTimeout(() => {
						if (
							content.find('table').innerHeight() >
							$(window).innerHeight() - heading.innerHeight()
						) {
							content.addClass('is-scroll');
						}
					}, 0);

					setTimeout(() => {
						if (heading.hasClass('is-open')) {
							bodyFix();
						} else {
							bodyFixReset();
						}
					}, 500);
				});

				content.on('click', (e) => {
					if ($(e.target).is('.js-accordion-content2')) {
						$('.js-accordion-heading2, .nk23_c-block13').removeClass('is-open')
						content.slideToggle(300, 'swing');

						setTimeout(() => {
							bodyFixReset();
							$('.js-scrolled-fixed').removeClass('is-fixed-btm');
						}, 500);
					}

				})
			}
		});
	}
});

$(function () {
	$('.js-hide').on('click', function () {
		if ($(this).text() == '投票成績を非表示にする') {
			$('.js-hide-content').slideUp();
			$(this).text('投票成績を表示する');
			$('.js-hide-link').hide();
			$(this).closest('.js-hide-top').addClass('is-active');
		} else if ($(this).text() == '投票成績を表示する') {
			$('.js-hide-content').slideDown();
			$(this).text('投票成績を非表示にする');
			$('.js-hide-link').show();
			$(this).closest('.js-hide-top').removeClass('is-active');
		}
	});
});
